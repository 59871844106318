import { StateCreator } from 'zustand'
import { MedplumClient } from '@medplum/core'
import { Dayjs } from 'dayjs'
import { Condition } from '@medplum/fhirtypes'
import {
  BaseConsentFragment,
  BaseOrganizationFragment,
  BasePatientFragment,
  PatientData_CareTeamListFragment,
  PatientData_GetPatientQuery,
  PatientData_GetPatientsByNameQuery,
  PatientData_GetPatientsListQuery
} from 'generated/graphql'

import { ProviderOrderPreferences } from 'fhir/Organization/helpers'
import { BaseEpisodeOfCareWithConsentFragment } from 'fhir/Patient/helpers'
import { ServiceTypeName } from 'fhir/HealthcareService/constants'
import { RootSlice } from './rootType'

import { GET_PATIENT_DATA_PATIENT, GET_PATIENT_DATA_PATIENTS_LIST } from './queries/patient.query'
import { formatPatientData } from './helpers/patient.helpers'

export type PatientListSchema = PatientSchema[]
export interface PatientSlice {
  patient: {
    list: PatientListSchema
    idToPatientMapping: Record<string, PatientSchema>
    isLoading: boolean
    careLocations: { careLocation1: string | null; careLocation2: string | null } | null
    parsePatient: (patient: PatientData_GetPatientQuery['Patient']) => void
    getPatient: (medplum: MedplumClient, id: string) => Promise<PatientSchema | null>
    loadAllPatients: (medplum: MedplumClient, count?: number) => Promise<void>
    errorMessage: object | string | null
    update: (patients: PatientData_GetPatientsByNameQuery['PatientList']) => void
  }
}

export interface PatientSchema {
  id: string
  name: string
  dateOfBirth: Dayjs | null
  gender: string | null
  mrn: string
  ssn: string | null
  addressLine: string | null
  addressLine2: string | null
  addressCity: string | null
  addressState: string | null
  addressPostalCode: string | null
  addressDisplay: string | null
  preferredName: string | null
  patientStatus: string
  admissionStatus: string
  primaryLanguage: string | null
  ethnicity: string | null
  conditions: Partial<Condition>[]
  sex: string | null
  careLocation1: string | null
  careLocation2: string | null
  primaryCareProvider: BaseOrganizationFragment | null
  providerOrderPreferences: ProviderOrderPreferences[]
  activeAuthorization: BaseConsentFragment | null
  currentCertPeriods: BaseEpisodeOfCareWithConsentFragment[] | null
  // FIXME: evaluate careTeam
  careTeam: PatientData_CareTeamListFragment[]
  caregiverTimezone: string
  serviceType: ServiceTypeName | null
  managingOrganization: BasePatientFragment['managingOrganization'] | null
  careTeamId: string | null
}

export const createPatientSlice: StateCreator<
  RootSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PatientSlice
> = (set) => ({
  patient: {
    list: [],
    idToPatientMapping: {},
    isLoading: false,
    errorMessage: null,
    patientData: null,
    careLocations: null,
    parsePatient: (patient: PatientData_GetPatientQuery['Patient']) => {
      if (!patient) return
      const formattedData = formatPatientData(patient)
      if (formattedData) {
        set((state) => {
          state.patient.idToPatientMapping[formattedData.id as string] = formattedData
        })
      }
    },
    // TODO (Jason): refactor this
    getPatient: async (medplum, id) => {
      try {
        set((state) => {
          state.patient.errorMessage = null
          state.patient.isLoading = true
        })

        const { data: patientData }: { data: PatientData_GetPatientQuery } = await medplum.graphql(
          GET_PATIENT_DATA_PATIENT,
          null,
          {
            id
          }
        )

        const patient = patientData?.Patient

        if (!patient?.id) {
          throw new Error('Patient ID not found')
        }
        const patientId = patient.id

        let formattedData: PatientSchema | undefined

        if (patient) {
          formattedData = formatPatientData(patient)
        }
        set((state) => {
          if (formattedData) {
            state.patient.idToPatientMapping[patientId] = formattedData
          }
          state.patient.errorMessage = null
        })

        return formattedData ?? null
      } catch (error) {
        set((state) => {
          state.patient.errorMessage = error?.message || 'Error getting patient data.'
        })

        return null
      } finally {
        set((state) => {
          state.patient.isLoading = false
        })
      }
    },
    loadAllPatients: async (medplum, count = 1000) => {
      set((state) => {
        state.patient.errorMessage = null
        state.patient.isLoading = true
      })
      try {
        let offset = 0
        const patientList: PatientData_GetPatientsListQuery['PatientList'] = []
        let currPatientList: PatientData_GetPatientsListQuery['PatientList'] = []

        // query all patients since there's no performance issue with the current data size
        while (offset === 0 || currPatientList?.length === count) {
          const { data, errors } = await medplum.graphql(GET_PATIENT_DATA_PATIENTS_LIST, null, {
            count,
            offset
          })
          currPatientList = data.PatientList
          patientList.push(...(currPatientList ?? []))
          offset += count

          if (errors) {
            set((state) => {
              state.patient.errorMessage = Array.isArray(state.patient.errorMessage)
                ? [...state.patient.errorMessage, ...errors]
                : errors
            })
          }
        }

        const parsedPatientList = patientList.reduce((acc, curr) => {
          if (!curr) return acc
          const formattedData = formatPatientData(curr)
          if (formattedData) {
            const currObject = { ...formattedData }
            acc.push(currObject)
          }
          return acc
        }, [] as PatientSchema[])

        const idToPatientMapping = parsedPatientList.reduce((acc, curr) => {
          if (curr?.id) {
            acc[curr.id] = curr
          }
          return acc
        }, {} as Record<string, PatientSchema>)

        set((state) => {
          if (patientList.length) {
            state.patient.list = parsedPatientList
            state.patient.idToPatientMapping = idToPatientMapping
          }
        })
      } catch (error) {
        set((state) => {
          state.patient.errorMessage = error?.message || 'Error getting patient data.'
        })
      } finally {
        set((state) => {
          state.patient.isLoading = false
        })
      }
    },
    update: (patients: PatientData_GetPatientsByNameQuery['PatientList']) => {
      if (!patients) return
      const parsedMapping = patients.reduce((acc, curr) => {
        if (!curr) return acc
        const formattedData = formatPatientData(curr)
        if (formattedData) {
          acc[formattedData.id as string] = formattedData
        }
        return acc
      }, {})

      set((state) => {
        state.patient.idToPatientMapping = {
          ...parsedMapping
        }
      })
    }
  }
})
