import { HealthcareService, Reference } from '@medplum/fhirtypes'
import { createReference } from 'fhir/utils'

export enum HealthcareTeam {
  CARE_OPS = 'CareOps',
  CLINICAL = 'Clinical',
  QA_NURSE = 'QANurse',
  CAREGIVER = 'Caregiver'
}

export enum ServiceTypeName {
  CO_CNA = 'CO-CNA',
  CO_IHSS = 'CO-IHSS',
  PA_HHA = 'PA-HHA',
  IN_ATTC = 'IN-ATTC',
  IN_SFC = 'IN-SFC',
  IN_HHA = 'IN-HHA',
  MA_HHA = 'MA-HHA'
}

// HealthcareService constants to represent the primary group for all Abbycare RNs.
const CLINICAL_HEALTHCARE_SERVICE_ID = process.env.NEXT_PUBLIC_CLINICAL_HEALTHCARE_SERVICE_ID
const CAREOPS_HEALTHCARE_SERVICE_ID = process.env.NEXT_PUBLIC_CAREOPS_HEALTHCARE_SERVICE_ID
const QA_NURSE_HEALTHCARE_SERVICE_ID = process.env.NEXT_PUBLIC_QA_NURSE_HEALTHCARE_SERVICE_ID
const CAREGIVER_HEALTHCARE_SERVICE_ID = process.env.NEXT_PUBLIC_CAREGIVER_HEALTHCARE_SERVICE_ID

export const HEALTHCARE_TEAM_REFERENCES: Record<HealthcareTeam, Reference<HealthcareService>> = {
  [HealthcareTeam.CARE_OPS]: createReference<HealthcareService>({
    reference: `HealthcareService/${CAREOPS_HEALTHCARE_SERVICE_ID}`,
    type: 'HealthcareService',
    id: CAREOPS_HEALTHCARE_SERVICE_ID ?? '',
    display: 'CareOps Team'
  }),
  [HealthcareTeam.CLINICAL]: createReference<HealthcareService>({
    reference: `HealthcareService/${CLINICAL_HEALTHCARE_SERVICE_ID}`,
    type: 'HealthcareService',
    id: CLINICAL_HEALTHCARE_SERVICE_ID ?? '',
    display: 'Clinical Team'
  }),
  [HealthcareTeam.QA_NURSE]: createReference<HealthcareService>({
    reference: `HealthcareService/${QA_NURSE_HEALTHCARE_SERVICE_ID}`,
    type: 'HealthcareService',
    id: QA_NURSE_HEALTHCARE_SERVICE_ID ?? '',
    display: 'QA Nurse Team'
  }),
  [HealthcareTeam.CAREGIVER]: createReference<HealthcareService>({
    reference: `HealthcareService/${CAREGIVER_HEALTHCARE_SERVICE_ID}`,
    type: 'HealthcareService',
    id: CAREGIVER_HEALTHCARE_SERVICE_ID ?? '',
    display: 'Caregiver'
  })
}
