import { useMedplum } from '@medplum/react'
import { createContext, FC } from 'react'
import { StateEnum } from 'constants/states'
import { AbbyAccessPolicy, parseAccessPolicies } from 'utils/accessPolicy'

export interface CanReadWriteResponse {
  canRead: boolean
  canWrite: boolean
}

interface AccessPolicyContextProps {
  isAdmin: boolean
  canReadWrite: (type: string) => CanReadWriteResponse
  accessPolicyList: AbbyAccessPolicy[]
  resourceStates: Record<string, { criteria: string }>
}

const AccessPolicyContext = createContext<AccessPolicyContextProps>({
  isAdmin: false,
  canReadWrite: () => ({ canRead: false, canWrite: false }),
  accessPolicyList: [],
  resourceStates: {}
})

interface AccessPolicyProviderProps {
  children: React.ReactNode
}

const allSupportedStates: string[] = [
  StateEnum.CO,
  StateEnum.PA,
  StateEnum.MA,
  StateEnum.IN,
  StateEnum.FL
]

/**
 * Fetches a user's `ProjectMembership`, and checks to see if the `resourceType` we're checking
 * has read or write access in any of the assigned policies. If a user is an admin, we grant all
 * permissions.
 *
 * We purposefully do not check a resources `hiddenFields`, `readonlyFields`, or `criteria` as
 * we haven't implemented these access control checks. If we need to limit or grant access based
 * on these properties, we will need to update this Provider to be inclusive.
 */
export const AccessPolicyProvider: FC<AccessPolicyProviderProps> = ({ children }) => {
  const medplum = useMedplum()
  const projectMembership = medplum.getProjectMembership()

  const accessPolicy = medplum.getAccessPolicy()

  const accessPolicyBasedOn = accessPolicy?.basedOn ?? []
  const accessPolicySet: Set<string> = new Set(
    accessPolicyBasedOn.map((item) => String(item.reference))
  )
  const accessPolicyList = projectMembership?.admin
    ? [AbbyAccessPolicy.ADMIN]
    : parseAccessPolicies(Array.from(accessPolicySet))

  const isAdmin = projectMembership?.admin ?? false

  const canReadWrite = (resourceType: string): CanReadWriteResponse => {
    const isAdmin = projectMembership?.admin

    if (isAdmin) {
      return { canRead: true, canWrite: true }
    }

    const resourcesOfType =
      accessPolicy?.resource?.filter((resource) => resource?.resourceType === resourceType) ?? []

    const canWrite = resourcesOfType.some((resource) => !resource?.readonly)
    const canRead = resourcesOfType.some((resource) => resource?.readonly) || canWrite

    return { canRead, canWrite }
  }

  const resourceStates =
    accessPolicy?.resource?.reduce((acc, resource) => {
      if (resource?.resourceType) {
        if (!acc[resource.resourceType]) {
          acc[resource.resourceType] = {
            criteria: {}
          }
        }

        const params = acc[resource.resourceType]?.criteria

        if (resource.criteria) {
          const queryParams = new URLSearchParams(new URL('https://' + resource.criteria).search)

          queryParams.forEach((value, key) => {
            if (!params[key]) {
              params[key] = []
            }

            params[key].push(value)
          })
        }
      }
      return acc
    }, {}) ?? {}

  if (isAdmin) {
    resourceStates['Patient'] = {
      criteria: {
        'address-state': allSupportedStates
      }
    }
  }

  if (!projectMembership || !accessPolicy) {
    return null
  }

  return (
    <AccessPolicyContext.Provider
      value={{
        isAdmin,
        canReadWrite,
        accessPolicyList,
        resourceStates
      }}
    >
      {children}
    </AccessPolicyContext.Provider>
  )
}

export default AccessPolicyContext
