import {
  Coding,
  Extension,
  QuestionnaireItem,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
  Reference
} from '@medplum/fhirtypes'
import dayjs, { Dayjs, duration } from 'dayjs'
import {
  BaseAppointmentFragment,
  BaseQuestionnaireResponseFragment,
  Get_Appointments_By_TypeQuery
} from 'generated/graphql'
import { QUESTIONNAIRE_COMPONENT_EXTENSION_URL } from 'fhir/Questionnaire/constants'
import { AssessmentType } from 'components/Clinical/Assessments/constants'
import { CERTIFICATION_PERIOD_DAYS } from 'utils/constants'
import { QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './questionnaire'
import { QuestionnaireAnswerType } from './types'
import {
  AllQuestionnaireItemType,
  InterventionInfo,
  SharedCareQuestionnaireItem,
  SharedCareQuestionnaireItemType
} from './hooks/constants'
import { RecertAssessmentQuestionnaireItem } from './RecertAssessment/types/questionnaire'
import { TASK_FREQUENCIES } from './hooks/constants'
import { SocAssessmentQuestionnaireItem } from './SocAssessment/types/questionnaire'
import { RocAssessmentQuestionnaireItem } from './RocAssessment/types/questionnaire'
import { RevisionOfParAssessmentQuestionnaireItem } from './RevisionOfParAssessment/types/questionnaire'

export const findExtensionAndReturnComponentRef = ({ extensions, linkId }): string => {
  const extension = extensions.find((ext) => {
    if (ext?.url && ext?.valueCodeableConcept) {
      return ext.url === QUESTIONNAIRE_COMPONENT_EXTENSION_URL && ext.valueCodeableConcept?.coding
    }
  })

  if (extension) {
    const elementRef = (extension.valueCodeableConcept?.coding?.[0]?.code || '') as string
    return elementRef
  }

  throw new Error(`Missing element type for item with linkId: ${linkId}`)
}

export const findComponentExtensionAndReturnDisplay = (extensions: Extension[]): string => {
  const extension = extensions.find((ext) => {
    if (ext?.url && ext?.valueCodeableConcept) {
      return ext.url === QUESTIONNAIRE_COMPONENT_EXTENSION_URL && ext.valueCodeableConcept?.coding
    }
  })
  if (extension) {
    return extension.valueCodeableConcept?.coding?.[0]?.display || ''
  }
  return ''
}

export const updateObjectByLinkId = (
  items: QuestionnaireResponseItem[],
  targetLinkId: string | undefined,
  newValue: QuestionnaireResponseItem
): QuestionnaireResponseItem[] => {
  return items.map((item) => {
    if (item.linkId === targetLinkId) {
      return {
        ...item,
        answer: newValue.answer
      }
    }
    if (item.item && Array.isArray(item.item)) {
      return { ...item, item: updateObjectByLinkId(item.item, targetLinkId, newValue) }
    }

    return item
  })
}

export const filterKeysWithSubstrings = <T>(
  inputObject: Record<string, T>,
  substrings: string[]
): Record<string, T> => {
  return Object.fromEntries(
    Object.entries(inputObject).filter(([key]) =>
      substrings.every((substring) => key.includes(substring))
    )
  )
}

export const extractAnswers = (
  questionnaireResponse: QuestionnaireResponse | QuestionnaireResponseItem | undefined
): Record<string, QuestionnaireResponseItemAnswer> => {
  const answersMap = {}

  const processItems = (items: QuestionnaireResponseItem[]): void => {
    items.forEach(({ linkId, answer, item: nestedItems }) => {
      if (answer) {
        answersMap[linkId as string] = answer.length === 1 ? answer[0] : answer
      }
      if (nestedItems) {
        processItems(nestedItems)
      }
    })
  }

  if (questionnaireResponse?.item) {
    processItems(questionnaireResponse.item)
  }

  return answersMap
}

export const formatDiagnosesList = (
  answers: Record<string, QuestionnaireResponseItemAnswer>
): string[] =>
  Object.values(answers).flatMap((answer) =>
    (Array.isArray(answer) ? answer : [answer])
      .map((item) => item?.valueCoding?.display)
      .filter(Boolean)
  )

export const getNestedItems = (
  items: QuestionnaireItem[]
): {
  question: QuestionnaireItem
  notes: QuestionnaireItem
  conditionalQuestions: QuestionnaireItem[]
} => {
  const questionGroupItems = {
    question: {} as QuestionnaireItem,
    notes: {} as QuestionnaireItem,
    conditionalQuestions: [] as QuestionnaireItem[]
  }
  items.forEach((item) => {
    const splitPath = item?.linkId?.split('/')
    if (splitPath && splitPath[splitPath.length - 1] === 'question') {
      questionGroupItems.question = item
    }
    if (splitPath && splitPath[splitPath.length - 1] === 'notes') {
      questionGroupItems.notes = item
    }
    if (splitPath && splitPath[splitPath.length - 1].includes('question-')) {
      questionGroupItems.conditionalQuestions.push(item)
    }
  })
  return questionGroupItems
}

export const getValueKeyForAnswerType = (answerType: QuestionnaireAnswerType): string => {
  switch (answerType) {
    case QuestionnaireAnswerType.STRING:
      return 'valueString'
    case QuestionnaireAnswerType.BOOLEAN:
      return 'valueBoolean'
    case QuestionnaireAnswerType.DATE:
      return 'valueDate'
    case QuestionnaireAnswerType.DATE_TIME:
      return 'valueString'
    // we need to use valueString because other wise it wouldn't save on Medplum
    // https://github.com/abbycare/abbycare/pull/699/files#r1428555435
    case QuestionnaireAnswerType.TIME:
      return 'valueString'
    case QuestionnaireAnswerType.DECIMAL:
      return 'valueDecimal'
    case QuestionnaireAnswerType.INTEGER:
      return 'valueInteger'
    case QuestionnaireAnswerType.REFERENCE:
      return 'valueReference'
    case QuestionnaireAnswerType.CODING:
      return 'valueCoding'
    default:
      throw new Error(`Cannot get value for answer type ${answerType}`)
  }
}

export const encodeValueForAnswerType = (
  value: any,
  answerType: QuestionnaireAnswerType
): boolean | number | string | Coding | Reference | undefined => {
  if (value === undefined || value === null || value === '') return undefined

  // TODO: have to save just the timestamp + timezone portion instead of
  // the entire datetime
  switch (answerType) {
    case QuestionnaireAnswerType.DATE_TIME:
    case QuestionnaireAnswerType.TIME:
      return dayjs(value).format()
    case QuestionnaireAnswerType.DATE:
      return dayjs(value).format('YYYY-MM-DD')
    case QuestionnaireAnswerType.DECIMAL:
      return parseFloat(value)
    case QuestionnaireAnswerType.BOOLEAN:
      return Boolean(value)
    default:
      return value
  }
}

export const decodeValueForAnswerType = (value: any, answerType: QuestionnaireAnswerType): any => {
  if (value === undefined || value === null) return value

  // TODO: check if dates have to make sense for times in range
  // e.g. 4pm to 9am, does second date have to be after first date
  switch (answerType) {
    case QuestionnaireAnswerType.DATE_TIME:
    case QuestionnaireAnswerType.DATE:
    case QuestionnaireAnswerType.TIME:
      return dayjs(value)
    default:
      return value
  }
}

export const getAnswerValuesFromItems = (
  questionnaireResponseItems: QuestionnaireResponseItem[],
  linkId: AllQuestionnaireItemType,
  rawAnswer = false
): any[] => {
  const answerType = QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE[linkId]
  if (answerType && answerType !== QuestionnaireAnswerType.GROUP) {
    const answer = questionnaireResponseItems?.find((i) => i.linkId === linkId)?.answer ?? []
    const valueKey = getValueKeyForAnswerType(answerType)
    // NOTE: this should allow returning null or undefined, since we might actually
    // want that information to be returned (e.g. when adding new goals via the UI)
    const answerValues = answer.flatMap((a) => a[valueKey])
    return answerValues.map((value) =>
      rawAnswer ? value : decodeValueForAnswerType(value, answerType)
    )
  } else if (answerType == QuestionnaireAnswerType.GROUP) {
    return questionnaireResponseItems?.find((i) => i.linkId === linkId)?.item ?? []
  }
  return []
}

export const createAnswerItemsFromValues = (
  questionnaireItemsWithValues: [SharedCareQuestionnaireItemType, unknown][]
): QuestionnaireResponseItem[] => {
  return questionnaireItemsWithValues.map(([questionnaireItem, value]) => {
    const answerType = QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE[questionnaireItem]

    if (!answerType)
      throw new Error(`Could not find answer type for questionnaire item ${questionnaireItem}`)

    const valueAsArray =
      value === null || value === undefined ? [] : Array.isArray(value) ? value : [value]
    return {
      linkId: questionnaireItem,
      answer: valueAsArray.map((v) => ({
        [getValueKeyForAnswerType(answerType)]: encodeValueForAnswerType(v, answerType)
      }))
    }
  })
}

export const getValueFromEvent = (e: any): any => {
  if (e && typeof e === 'object' && 'target' in e) {
    const target = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    if (target && 'value' in target) {
      return target.value
    }
  } else {
    return e
  }
}

export const getSocAppointmentStartDate = (
  socAppointmentData: Get_Appointments_By_TypeQuery
): Dayjs | undefined => {
  const appointmentList =
    socAppointmentData.AppointmentList?.filter((a): a is BaseAppointmentFragment => !!a) ?? []
  const latestAppointment = appointmentList.find((a) => !!a)
  if (!latestAppointment) {
    return
  }

  const appointmentStartString = latestAppointment.start
  const appointmentDate = appointmentStartString ? dayjs(appointmentStartString) : undefined

  return appointmentDate
}

export const composeInterventionInstruction = (
  rawIntervention: string,
  intervention = '',
  type = '',
  indication = '',
  frequency = ''
): string => {
  const instruction = rawIntervention
    .replace('(Intervention/task)', intervention)
    .replace('(Intervention/task dropdown)', intervention)
    .replace('(type)', type)
    .replace('(type dropdown)', type)
    .replace('(indication)', indication)
    .replace('(indication dropdown)', indication)
    .replace('(frequency)', frequency)
    .replace('(Frequency)', frequency)

  return instruction
}

export const getInterventionFrequency = (frequency: string[] | undefined): string => {
  if (!frequency) return ''
  if (frequency.length === 0) {
    return 'Daily'
  }
  return frequency.join('s,') + 's'
}

export const getInterventionVisits = (visitNumbers: number[] | undefined): string => {
  if (!visitNumbers) return ''
  if (visitNumbers.length === 0) {
    return 'All'
  }

  return visitNumbers.join(',')
}

export const getCareHoursForAssessment = ({
  assessmentType,
  flatAnswers
}: {
  assessmentType: AssessmentType | null
  flatAnswers: Record<string, QuestionnaireResponseItem>
}): number | undefined => {
  switch (assessmentType) {
    case AssessmentType.SOC: {
      const [requestedHours] = getAnswerValuesFromItems(
        Object.values(flatAnswers),
        SocAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_REQUESTED_HOURS
      )
      return requestedHours
    }
    case AssessmentType.RECERT: {
      const [currentHours] = getAnswerValuesFromItems(
        Object.values(flatAnswers),
        RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_CURRENT_HOURS
      )
      return currentHours
    }
    case AssessmentType.REVISION_OF_PAR: {
      const [currentHours] = getAnswerValuesFromItems(
        Object.values(flatAnswers),
        RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_CURRENT_HOURS
      )
      return currentHours
    }
    case AssessmentType.ROC: {
      const [currentHours] = getAnswerValuesFromItems(
        Object.values(flatAnswers),
        RocAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_CURRENT_HOURS
      )
      return currentHours
    }
    case AssessmentType.DISCHARGE:
    case null:
    default:
      return undefined
  }
}

export type InterventionInfoWithDuration = {
  interventions: InterventionInfo[]
  duration: string
}
export const mapCustomVisitsToInterventions = (
  flatAnswers: Record<string, QuestionnaireResponseItem>,
  interventions: InterventionInfo[]
): Record<string, InterventionInfoWithDuration> => {
  const customVisits =
    flatAnswers[SharedCareQuestionnaireItem('VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS')]?.answer ??
    []

  const visitNumberMap = customVisits.reduce(
    (acc, visit, index): Record<string, InterventionInfoWithDuration> => {
      const visitNumber = index + 1

      const visitPeriod =
        visit.item?.find(
          (item) =>
            item.linkId ===
            SharedCareQuestionnaireItem('VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD')
        )?.item ?? []

      const [[start], [end]] = [
        SharedCareQuestionnaireItem('VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_START'),
        SharedCareQuestionnaireItem('VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_END')
      ].map((linkId) => getAnswerValuesFromItems(visitPeriod, linkId))

      const hoursDifference = end.diff(start, 'hour', true)
      const pluralSuffix = hoursDifference === 1 ? '' : 's'
      const duration = `${hoursDifference.toFixed(1)} hour${pluralSuffix}`

      acc[visitNumber] = { duration, interventions: [] }
      return acc
    },
    {} as Record<string, InterventionInfoWithDuration>
  )

  interventions.forEach((intervention) => {
    if (intervention?.visitNumbers && intervention.visitNumbers.length > 0) {
      intervention.visitNumbers.forEach((visitNumber) => {
        if (visitNumberMap[visitNumber]) {
          visitNumberMap[visitNumber].interventions.push(intervention)
        }
      })
    } else {
      Object.values(visitNumberMap).forEach((visitObject) => {
        visitObject.interventions.push(intervention)
      })
    }
  })

  return visitNumberMap
}

export const formatTreatmentFrequencyString = (careHours: number, daysPerWeek: number): string => {
  const formatPluralString = (value: number): string => (value !== 1 ? 's' : '')
  const treatmentFrequency = `Frequency Aide: ${careHours} hour${formatPluralString(
    careHours
  )}/day x ${daysPerWeek} day${formatPluralString(
    daysPerWeek
  )} per week x ${CERTIFICATION_PERIOD_DAYS} days`

  return treatmentFrequency
}

export const generateTreatmentFrequencyString = (
  careHours: number,
  customVisitsToInterventionsMap: Record<string, InterventionInfoWithDuration>
): string => {
  const daysVisitsOccur = new Set<string>()

  for (const visitInterventions of Object.values(customVisitsToInterventionsMap)) {
    for (const intervention of visitInterventions.interventions) {
      const { frequency } = intervention
      if (frequency && frequency.length > 0) {
        frequency.forEach((day) => daysVisitsOccur.add(day))
      } else {
        TASK_FREQUENCIES.forEach((day) => daysVisitsOccur.add(day))
      }
    }
  }
  const numberOfDaysVisitsOccur = daysVisitsOccur.size

  const treatmentFrequency = formatTreatmentFrequencyString(careHours, numberOfDaysVisitsOccur)
  return treatmentFrequency
}

export const getAssessmentDisplay = (
  assessment: BaseQuestionnaireResponseFragment,
  qualifiedHours: number | undefined
): string => {
  const qualifiedHoursDuration = qualifiedHours ? duration({ hours: qualifiedHours }) : null
  const hours = qualifiedHoursDuration?.hours()
  const minutes = qualifiedHoursDuration?.minutes()

  let qualifiedHoursDisplay: string
  if (hours && hours < 0) {
    qualifiedHoursDisplay = `(no hours)`
  } else if (hours && minutes) {
    qualifiedHoursDisplay = `for ${hours} hours ${minutes} minutes`
  } else if (hours) {
    qualifiedHoursDisplay = `for ${hours} hours`
  } else if (minutes) {
    qualifiedHoursDisplay = `for ${minutes} minutes`
  } else {
    qualifiedHoursDisplay = `(no hours)`
  }

  const lastEditedTime = assessment.authored ? dayjs.utc(assessment.authored) : null

  const assessmentDisplay = [
    `PAT`,
    qualifiedHoursDisplay,
    ...(lastEditedTime ? [`updated on ${lastEditedTime?.format('MMM D, YYYY')}`] : [])
  ].join(' ')

  return assessmentDisplay
}

export const validateSameDayAppointmentOverviewSection = (
  value: string[],
  caregiverTimezone: string
): Promise<Error | void> => {
  if (!value) {
    return Promise.resolve()
  }

  const appointmentTimeStart = dayjs(value[0]).tz(caregiverTimezone)
  const appointmentTimeEnd = dayjs(value[1]).tz(caregiverTimezone)

  const isOnSameDay = appointmentTimeStart.isSame(appointmentTimeEnd, 'day')

  if (isOnSameDay) {
    return Promise.resolve()
  } else {
    return Promise.reject(new Error('Start and End time should be on the same day.'))
  }
}

export const validateSOCDate = (
  value: string | Date,
  appointmentTimeStart: string | undefined,
  timezone: string
): Promise<Error | void> => {
  if (!value) {
    return Promise.resolve()
  }

  if (!appointmentTimeStart) {
    return Promise.reject(new Error(`First select an Appointment Time`))
  }

  const appointmentTimeStartDayjs = dayjs(appointmentTimeStart).tz(timezone)
  const socDateDayjs = dayjs(value).tz(timezone)

  const isValidSocDate =
    socDateDayjs.isSame(appointmentTimeStartDayjs, 'day') ||
    socDateDayjs.isAfter(appointmentTimeStartDayjs, 'day')

  if (isValidSocDate) {
    return Promise.resolve()
  }
  return Promise.reject(
    new Error(
      `Start of Care Appointment date has to be the same day, or after the Appointment date`
    )
  )
}
