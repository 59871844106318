import { StateCreator } from 'zustand'
import {
  BasePractitionerFragment,
  UsePatientCareTeam_CareTeamFragment,
  UsePatientCareTeamQuery
} from 'generated/graphql'
import { selectCareTeamPractitionersWithRole, selectPrimaryCareTeam } from 'fhir/CareTeam/helpers'
import { CareTeamMemberRole } from 'fhir/CareTeam/constants'
import { RootSlice } from './rootType'

export interface CareTeam {
  id: string
  resourceType: string
  subject: UsePatientCareTeam_CareTeamFragment['subject']
  caregivers: BasePractitionerFragment[]
  orderingPhysicians: BasePractitionerFragment[]
  registeredNurses: BasePractitionerFragment[]
  primaryCarePhysicians: BasePractitionerFragment[]
}
export interface CareTeamSlice {
  careTeam: {
    byPatientId: Record<string, UsePatientCareTeam_CareTeamFragment[]>
    loadCareTeam: (data: UsePatientCareTeamQuery) => void
    byId: Record<string, CareTeam>
  }
}

const defaultState: Pick<CareTeamSlice['careTeam'], 'byPatientId' | 'byId'> = {
  byPatientId: {},
  byId: {}
}

export const createCareTeamSlice: StateCreator<
  RootSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  CareTeamSlice
> = (set) => ({
  careTeam: {
    ...defaultState,
    loadCareTeam: (data: UsePatientCareTeamQuery) => {
      set((draftState) => {
        const careTeamList =
          data?.CareTeamList?.filter((c): c is UsePatientCareTeam_CareTeamFragment => !!c) ?? []

        const primaryCareTeam = selectPrimaryCareTeam(
          careTeamList as UsePatientCareTeam_CareTeamFragment[]
        )
        if (!primaryCareTeam?.id) return

        const { id: careTeamId, resourceType, subject } = primaryCareTeam
        const caregivers = selectCareTeamPractitionersWithRole(
          primaryCareTeam,
          CareTeamMemberRole.Caregiver
        )
        const orderingPhysicians = selectCareTeamPractitionersWithRole(
          primaryCareTeam,
          CareTeamMemberRole.OrderingPhysician
        )

        const registeredNurses = selectCareTeamPractitionersWithRole(
          primaryCareTeam,
          CareTeamMemberRole.RegisteredNurse
        )

        const primaryCarePhysicians = selectCareTeamPractitionersWithRole(
          primaryCareTeam,
          CareTeamMemberRole.PrimaryCarePhysician
        )

        const processedCareTeam = {
          id: careTeamId,
          resourceType,
          subject,
          caregivers,
          orderingPhysicians,
          registeredNurses,
          primaryCarePhysicians
        }

        const patientId = subject?.id as string

        draftState.patient.idToPatientMapping[patientId].careTeamId = careTeamId as string
        draftState.careTeam.byId[careTeamId] = processedCareTeam
      })
    }
  }
})
