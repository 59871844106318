import {
  ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE,
  ABBYCARE_TERMINOLOGY_CODES_BASE
} from 'fhir/constants'

export const ENCOUNTER_VISIT_NUMBER_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/encounter-visit-number`

export const ENCOUNTER_REASON_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/reason`

export enum EncounterStatus {
  PLANNED = 'planned',
  ARRIVED = 'arrived',
  TRIAGED = 'triaged',
  IN_PROGRESS = 'in-progress',
  ON_LEAVE = 'onleave',
  FINISHED = 'finished',
  CANCELLED = 'cancelled',
  ENTERED_IN_ERROR = 'entered-in-error'
}

export const AUTHORIZED_ENCOUNTER_STATUSES = [
  EncounterStatus.PLANNED,
  EncounterStatus.ARRIVED,
  EncounterStatus.TRIAGED,
  EncounterStatus.IN_PROGRESS,
  EncounterStatus.ON_LEAVE,
  EncounterStatus.FINISHED,
  EncounterStatus.CANCELLED
]

export const pendingEccounterStatuses = [EncounterStatus.ON_LEAVE]
export const cancelledEncounterStatuses = [EncounterStatus.CANCELLED]
export const chartedEncounterStatuses = [EncounterStatus.FINISHED]
export const chartableEncounterStatuses = [
  EncounterStatus.PLANNED,
  EncounterStatus.ARRIVED,
  EncounterStatus.TRIAGED,
  EncounterStatus.IN_PROGRESS
]

export const ENCOUNTER_PARTICIPANT_TYPE_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/encounter-participant-type`

export enum EncounterParticipantType {
  CAREGIVER = 'caregiver'
}

// Effective visit status displayed to user based on encounter, appointment, and task resources
export enum CalculatedVisitStatus {
  SCHEDULED = 'scheduled',
  SUBMITTED = 'submitted',
  MISSED = 'missed',
  LATE = 'late',
  APPROVED = 'approved',
  DENIED = 'denied',
  HOLD = 'hold',
  UNKNOWN = 'unknown'
}

// Maps to visit status in the API: api/services/visit/visit_services.py
export enum VisitStatus {
  SCHEDULED = 'scheduled',
  ON_HOLD = 'on-hold',
  MISSED = 'missed',
  DELETED = 'deleted'
}

export const CalculatedVisitStatusToVisitStatus = {
  scheduled: VisitStatus.SCHEDULED,
  hold: VisitStatus.ON_HOLD,
  missed: VisitStatus.MISSED
}

export const ENCOUNTER_CLASS_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/encounter-class`

export enum EncounterClassCode {
  CNA_VISIT = 'cna-visit',
  HHA_STANDARD_SHIFT = 'hha-standard-shift',
  HHA_FLEXIBLE_SHIFT = 'hha-flexible-shift'
}

export function getVisitName(classCode: EncounterClassCode | string | null | undefined): string {
  switch (classCode) {
    case EncounterClassCode.CNA_VISIT:
      return 'CNA Visit'
    case EncounterClassCode.HHA_STANDARD_SHIFT:
    case EncounterClassCode.HHA_FLEXIBLE_SHIFT:
      return 'HHA Flex Shift'
    default:
      return 'Visit'
  }
}

const ENCOUNTER_EVV_STATUS_LOCATION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-location`
const ENCOUNTER_EVV_STATUS_TIME = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-time`
const ENCOUNTER_EVV_STATUS_SIGNATURE = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-signature`
const ENCOUNTER_EVV_STATUS_TASK_COMPLETION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-task-completion`

export const ENCOUNTER_STATUS_CHANGE_REASON_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-status-history-reason`
export const ENCOUNTER_STATUS_CHANGE_REASON_NOTES_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-status-history-reason-notes`

export const ENCOUNTER_REASON_FOR_DELAYED_CHARTING = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/reason-for-delayed-charting`
export const ENCOUNTER_BETTER_SUPPORT = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/feedback-for-better-support`

export enum EncounterEVVExtensionTypes {
  TIME = 'time',
  SIGNATURE = 'signature',
  TASK_COMPLETION = 'task-completion'
}

export type EncounterEVVExtensions =
  | typeof ENCOUNTER_EVV_STATUS_LOCATION
  | typeof ENCOUNTER_EVV_STATUS_TIME
  | typeof ENCOUNTER_EVV_STATUS_SIGNATURE
  | typeof ENCOUNTER_EVV_STATUS_TASK_COMPLETION

export type EncounterStatusHistoryExtensions =
  | typeof ENCOUNTER_STATUS_CHANGE_REASON_EXTENSION_URL
  | typeof ENCOUNTER_STATUS_CHANGE_REASON_NOTES_EXTENSION_URL

export const EncounterEVVExtensionsMap: Record<EncounterEVVExtensionTypes, EncounterEVVExtensions> =
  {
    [EncounterEVVExtensionTypes.TIME]: ENCOUNTER_EVV_STATUS_TIME,
    [EncounterEVVExtensionTypes.SIGNATURE]: ENCOUNTER_EVV_STATUS_SIGNATURE,
    [EncounterEVVExtensionTypes.TASK_COMPLETION]: ENCOUNTER_EVV_STATUS_TASK_COMPLETION
  }

export enum CNAVisitEncounterEVVStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  INDETERMINATE = 'indeterminate'
}

export const ENCOUNTER_CARE_PLAN_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-care-plan`

export enum EncounterStatusChangeReason {
  PATIENT_WITH_OTHER_CAREGIVER = 'patient-with-other-caregiver',
  CAREGIVER_UNAVAILABLE = 'caregiver-unavailable',
  HOSPITALIZATION = 'hospitalization',
  INVALID_INSURANCE = 'invalid-insurance',
  ER_VISIT = 'er-visit',
  PATIENT_APPOINTMENT_CONFLICT = 'patient-appointment-conflict',
  PATIENT_SCHOOL_HOURS_CONFLICT = 'patient-school-hours-conflict',
  OTHER = 'other'
}

export const EncounterStatusChangeReasonToDisplay: Record<EncounterStatusChangeReason, string> = {
  [EncounterStatusChangeReason.PATIENT_WITH_OTHER_CAREGIVER]: 'Patient with other parent/guardian',
  [EncounterStatusChangeReason.CAREGIVER_UNAVAILABLE]: 'Caregiver unavailable',
  [EncounterStatusChangeReason.HOSPITALIZATION]: 'Hospitalization (admitted)',
  [EncounterStatusChangeReason.ER_VISIT]: 'ER Visit',
  [EncounterStatusChangeReason.PATIENT_APPOINTMENT_CONFLICT]:
    'Patient conflict with medical appointment',
  [EncounterStatusChangeReason.PATIENT_SCHOOL_HOURS_CONFLICT]: 'Patient conflict with school hours',
  [EncounterStatusChangeReason.INVALID_INSURANCE]: 'Invalid insurance',
  [EncounterStatusChangeReason.OTHER]: 'Other'
}
