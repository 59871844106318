import { Encounter, EncounterHospitalization, Identifier, Period } from '@medplum/fhirtypes'
import { useEncounterRepository } from './useEncounterRepository'

export interface CreateEncounterParams {
  type: Encounter['type']
  classCode: Encounter['class']
  subject: Encounter['subject']
  admissionDate: Period['start']
  dischargeDate: Period['end']
  reason: Encounter['reasonCode']
  facility?: Encounter['serviceProvider']
  outcome?: EncounterHospitalization['dischargeDisposition']
  rbacIdentifiers: Identifier[]
}

interface EncounterService {
  createEncounter(params: CreateEncounterParams): Promise<Encounter>
}

export const useEncounterService = (): EncounterService => {
  const encounterRepository = useEncounterRepository()

  const createEncounter = async ({
    type,
    classCode,
    subject,
    admissionDate,
    dischargeDate,
    reason,
    facility,
    outcome,
    rbacIdentifiers
  }: CreateEncounterParams): Promise<Encounter> => {
    return await encounterRepository.createEncounter({
      type,
      classCode,
      subject,
      admissionDate,
      dischargeDate,
      reason,
      serviceProvider: facility,
      outcome,
      rbacIdentifiers: rbacIdentifiers
    })
  }

  return {
    createEncounter
  }
}
