import { formatAddress as medplumFormatAddress } from '@medplum/core'
import { Address as MedplumAddress } from '@medplum/fhirtypes'
import { Address as GraphQlAddress } from 'generated/graphql'

const addressUseHierarchy = ['home', 'work', '', 'temp', 'old', 'billing']
const addressTypeHierarchy = ['both', 'postal', 'physical', '']

type Address = MedplumAddress | GraphQlAddress

type PrimaryAddressOptions = {
  type?: MedplumAddress['type']
  use?: MedplumAddress['use']
}

export interface AddressForFormDisplay {
  line?: string | null
  city?: string | null
  state?: string | null
  postalCode?: string | null
  text?: string | null
}
export const parseAddressForFormDisplay = <T extends Address>(
  address?: T | null
): AddressForFormDisplay | null => {
  return {
    line: address?.line?.[0],
    city: address?.city,
    state: address?.state,
    postalCode: address?.postalCode,
    text: formatAddress(address)
  }
}

export const parsePrimaryAddressForFormDisplay = <T extends Address>(
  address?: T[] | null,
  options?: PrimaryAddressOptions
): AddressForFormDisplay | null => {
  const primaryAddress = getPrimaryAddress(address ?? [], options)
  return parseAddressForFormDisplay(primaryAddress)
}
export const getPrimaryAddress = <T extends Address>(
  addresses: T[],
  options?: PrimaryAddressOptions
): T | null => {
  if (options) {
    const { type, use } = options
    return (
      addresses.find((address) => {
        const hasType = !type || address.type === type
        const hasUse = !use || address.use === use
        return hasType && hasUse
      }) ?? null
    )
  }

  const sortedAddresses = addresses.sort((a, b) => {
    const val = addressUseHierarchy.indexOf(a.use ?? '') - addressUseHierarchy.indexOf(b.use ?? '')

    if (val !== 0) {
      return val
    }

    return addressTypeHierarchy.indexOf(a.type ?? '') - addressTypeHierarchy.indexOf(b.type ?? '')
  })

  return sortedAddresses[0] ?? null
}

export const getIndexOfPrimaryAddress = <T extends Address>(
  addresses: T[],
  options?: PrimaryAddressOptions
): number | undefined => {
  const primaryAddress = getPrimaryAddress(addresses, options)
  return (
    addresses.findIndex(
      (address) => address.type === primaryAddress?.type && address.use === primaryAddress?.use
    ) ?? undefined
  )
}

export const formatAddress = <T extends Address>(address?: T | null): string | null => {
  if (!address) {
    return null
  }

  const hasAddress =
    (address.line?.length ?? 0) > 0 || !!address.city || !!address.state || !!address.postalCode

  try {
    if (hasAddress) {
      return medplumFormatAddress(address as MedplumAddress)
    }
    return address.text ?? null
  } catch {
    return address.text ?? null
  }
}

export const formatPrimaryAddress = <T extends Address>(
  address?: T[] | null,
  options?: PrimaryAddressOptions
): string | null => {
  const primaryAddress = getPrimaryAddress(address ?? [], options)
  return formatAddress(primaryAddress)
}

export const formatConcenatedPostalCode = (postalCode: string): string => {
  // Expect a string in format XXXXXYYYY. Split this up into XXXXX-YYYY. if
  // there are only 5 digits, then only return first 5 digits.
  if (!postalCode || postalCode.length <= 5) {
    return postalCode ?? ''
  }
  return `${postalCode.slice(0, 5)}-${postalCode.slice(5)}`
}
