import { QuestionnaireAnswerType } from 'components/Assessments/types'
import { RevisionOfParAssessmentQuestionnaireItem } from './questionnaire'

export const REVISION_OF_PAR_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE = {
  // Assessment overview
  [RevisionOfParAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START]:
    QuestionnaireAnswerType.DATE_TIME,
  [RevisionOfParAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END]:
    QuestionnaireAnswerType.DATE_TIME,
  [RevisionOfParAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_IN_PERSON]:
    QuestionnaireAnswerType.BOOLEAN,

  // Cert Period Recap
  [RevisionOfParAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CONSENT]:
    QuestionnaireAnswerType.REFERENCE,

  // Authorizations
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION]: QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_AUTHORIZATION_SOURCE]:
    QuestionnaireAnswerType.REFERENCE,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_CURRENT_HOURS]:
    QuestionnaireAnswerType.DECIMAL,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_IS_REVISING_CARE_HOURS]:
    QuestionnaireAnswerType.BOOLEAN,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_REVISED_CARE_HOURS]:
    QuestionnaireAnswerType.DECIMAL,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_REASON_FOR_REVISION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_ADDITIONAL_DETAILS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_ORDERING_PHYSICIAN]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_PCP]:
    QuestionnaireAnswerType.STRING,

  // Visit scheduling
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_WAKEUP_TIME]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_BEDTIME]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_DAY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD_START]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD_END]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_NAME]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD_START]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD_END]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_REPEATS_ON_DAY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VALID]:
    QuestionnaireAnswerType.BOOLEAN,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_START]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_END]:
    QuestionnaireAnswerType.TIME,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_CAREGIVER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_WEEKDAY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_CHANGE_REASON]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_ADDITIONAL_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VISIT_SCHEDULING_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Medical background
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_PRIMARY_DIAGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_OTHER_DIAGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_EQUIPMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_DME_PROVIDER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_WAIVERS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADDITIONAL_SERVICES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_PROGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_DNR]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_FUNCTIONAL_LIMITATIONS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_ACTIVITIES_PERMITTED]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_MEDICAL_HISTORY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_SURGICAL_HISTORY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_HEP_B]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_MENINGOCOCCAL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_COVID_19]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DTAP]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_HPV]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_RSV]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_NOTES]:
    QuestionnaireAnswerType.STRING,

  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_TRIAGE_CODE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_EMERGENCY_PLAN]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFE_ROOM]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_ASSESSMENT_OXYGEN]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_SAFETY_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOMEBOUND_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FINANCE_RISK]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_ENVIRONMENT_SAFETY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_NURSE_SAFETY_RISKS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFETY_MEASURES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_HEADACHES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_HEADACHE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_NEURO_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_PHQ2_SCREENING]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_PHQ2_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_INTEREST]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_DEPRESSED]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_CAREGIVER_DIFFICULTY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COMMUNICATION_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COGNITIVE_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_HISTORY_SUBSTANCE_ABUSE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SUBSTANCE_ABUSE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_MUSCULOSKELETAL_MUSCULOSKELETAL_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_MUSCULOSKELETAL_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_LINES_DRAINS_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_THERAPIES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_THERAPY_PROVIDER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_PLAN]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_DURATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_FREQUENCY]:
    QuestionnaireAnswerType.INTEGER,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EYES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EARS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_NOSE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TOUCH]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TASTE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_GOAL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_FACE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_LEGS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_ACTIVITY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CRY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CONSOLABILITY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_WNL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_HEART_SOUNDS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_RESPIRATORY_WNL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_COUGH]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_OXYGEN]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_LUNG_SOUNDS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_WNL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_CYCLE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GI_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_BM_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GU_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_AMOUNT]:
    QuestionnaireAnswerType.INTEGER,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TOTAL_NUMBER]:
    QuestionnaireAnswerType.INTEGER,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_DURATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SITE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SIZE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_WNL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_DIET_REQUIREMENTS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_SCREENING]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GASTROINTESTINAL_HISTORY_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_COLOR]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_ISSUES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_NAILS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_IDENTIFIED_WOUNDS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_WOUND_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_LESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_LESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_LESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_LESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_LESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_LESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_LESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_PREVIOUS_APPOINTMENTS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_FUTURE_APPOINTMENTS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_CARE_TEAM_COORDINATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_ORDERS_FOR_TREATMENT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_DATE_OF_VERBAL_COMMUNICATION]:
    QuestionnaireAnswerType.DATE,

  // Medications
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS]: QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DRUGBANK_PCID]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_NAME]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_ROUTE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_NUMBER]:
    QuestionnaireAnswerType.DECIMAL,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_UNIT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INDICATIONS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_FREQUENCY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_NUMBER]:
    QuestionnaireAnswerType.DECIMAL,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_UNIT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INSTRUCTIONS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_USAGE_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_START_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DISCONTINUE_DATE]:
    QuestionnaireAnswerType.DATE,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_CONTROLLED]:
    QuestionnaireAnswerType.BOOLEAN,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_LIQUID]:
    QuestionnaireAnswerType.BOOLEAN,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INFECTION]:
    QuestionnaireAnswerType.BOOLEAN,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.MEDICATIONS_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Skilled Needs
  [RevisionOfParAssessmentQuestionnaireItem.SKILLED_NEEDS]: QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED]: QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_NAME]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_SCORE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_NOTE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_PREVIOUS_NOTE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SKILLED_NEED_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Discharge Planning
  [RevisionOfParAssessmentQuestionnaireItem.DISCHARGE_PLANNING]: QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.DISCHARGE_PLANNING_DISCHARGE_PLANS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.DISCHARGE_PLANNING_REHABILITATION_POTENTIAL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.DISCHARGE_PLANNING_NOTES]:
    QuestionnaireAnswerType.STRING,

  // Plan of Care
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE]: QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_GOALS]: QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_GOALS_GOAL]:
    QuestionnaireAnswerType.INTEGER,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO]:
    QuestionnaireAnswerType.GROUP,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INTERVENTION]:
    QuestionnaireAnswerType.INTEGER,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_CATEGORY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_GOAL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TASK]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INDICATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INSTRUCTION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_PATIENT_CENTERED_GOAL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_ADDITIONAL_DETAILS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_VISITS]:
    QuestionnaireAnswerType.INTEGER,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_FREQUENCY]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.PLAN_OF_CARE_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Supervisory Review
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_ID_BADGE]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_HONORING_RIGHTS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EMERGENCY_PROTOCOL]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_HYGIENE_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_REPORTING_CHANGES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_GRIEVANCE_COMPLAINT]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_POC_DOC_REVIEWED_WITH_CAREGIVER]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_PPE_ACCESS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_SUPERVISION_OBSERVED]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_TASK_OBSERVED]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_PATIENT_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_COMMENTS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EDUCATION_CAREGIVER_EDUCATION_TOPIC]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EDUCATION_EDUCATION_PROVIDED]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Vitals
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_BLOOD_PRESSURE]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_BLOOD_SUGAR]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_TEMPERATURE]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_HEIGHT]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_WEIGHT]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_O2_SATURATION]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_RESPIRATIONS]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_PULSE]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.VITALS_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Narrative background
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_BACKGROUND]: QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_SYSTEM_SUMMARIES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_CLINICAL_OVERVIEW]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_SKILLED_NEEDS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_PATIENT_SERVICES]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_CARE_COORDINATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_PATIENT_GOALS]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_CLINICAL_EDUCATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.NARRATIVE_FINAL_NOTES]: QuestionnaireAnswerType.STRING,

  // Final Steps
  [RevisionOfParAssessmentQuestionnaireItem.FINAL_STEPS_PENDING_REQUIRED_DOCUMENTATION]:
    QuestionnaireAnswerType.STRING,
  [RevisionOfParAssessmentQuestionnaireItem.FINAL_STEPS_INTERNAL_NOTES]:
    QuestionnaireAnswerType.STRING
}
