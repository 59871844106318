import { useFlags } from 'launchdarkly-react-client-sdk'

export enum FeatureFlagKey {
  AbbyAidOnboarding = 'abbyaid_onboarding',
  AbbyosVisitBilling = 'abbyos_visit_billing',
  DebugLogs = 'debug-logs',
  Flows = 'flows',
  EnableEditingOrders = 'enable-editing-orders',
  isAutoFaxEnabled = 'auto_fax',
  isServicePlanEnabled = 'service_plan',
  enableAdminOnboardingInternalPage = 'admin-onboarding-internal-page',
  enableCareAssessmentRedesign = 'care-assessment-redesign',
  enableNewReportsV2 = 'new-reports-v-2'
}

export type FeatureFlagName = keyof FeatureFlags

export interface FeatureFlags {
  abbyaidOnboarding: boolean
  abbyosVisitBilling: boolean
  debugLogs: boolean
  flows: boolean
  enableEditingOrders: boolean
  isAutoFaxEnabled: boolean
  isServicePlanEnabled: boolean
  enableAdminOnboardingInternalPage: boolean
  enableCareAssessmentRedesign: boolean
  enableNewReportsV2: boolean
}

const useFeatureFlags = (): FeatureFlags => {
  const flags = useFlags()

  const featureFlags = {
    abbyaidOnboarding: flags[FeatureFlagKey.AbbyAidOnboarding],
    abbyosVisitBilling: flags[FeatureFlagKey.AbbyosVisitBilling],
    debugLogs: flags[FeatureFlagKey.DebugLogs],
    flows: flags[FeatureFlagKey.Flows],
    enableEditingOrders: flags[FeatureFlagKey.EnableEditingOrders],
    isAutoFaxEnabled: flags[FeatureFlagKey.isAutoFaxEnabled],
    isServicePlanEnabled: flags[FeatureFlagKey.isServicePlanEnabled],
    enableAdminOnboardingInternalPage: flags[FeatureFlagKey.enableAdminOnboardingInternalPage],
    enableCareAssessmentRedesign: flags[FeatureFlagKey.enableCareAssessmentRedesign],
    enableNewReportsV2: flags[FeatureFlagKey.enableNewReportsV2]
  }

  return featureFlags
}

export default useFeatureFlags
