import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Typography } from 'antd'
import styled from 'styled-components'
import { captureException } from '@sentry/nextjs'
import { InfoCircle } from 'public/assets/icons'
import { SentrySources } from 'utils/sentry'

const { Text } = Typography

function CheckAppVersionUpdate(): JSX.Element {
  const [showModal, setShowModal] = useState(false)

  const fetchBuildId = async (): Promise<string> => {
    const { fetch } = window
    const buildIdUrl = '/api/build-id'
    try {
      const response = await fetch(buildIdUrl)
      const { buildId } = await response.json()
      return buildId
    } catch (error) {
      captureException(error, {
        tags: { source: SentrySources.NEXTJS_BUILD_ID }
      })
      return ''
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      const buildId = await fetchBuildId()
      if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
        setShowModal(true)
        clearInterval(interval)
      }
    }, 60000)

    return () => interval && clearInterval(interval)
  }, [])

  const reloadWindow = (): void => {
    window.location.reload()
  }

  const renderCTAButton = (): JSX.Element => {
    return (
      <Button onClick={reloadWindow} disabled={false}>
        Refresh
      </Button>
    )
  }

  return (
    <Modal
      open={showModal}
      onOk={reloadWindow}
      closable
      onCancel={() => setShowModal(false)}
      title="New version available"
      footer={renderCTAButton()}
    >
      <AlertBanner>
        <Space size="middle">
          <InfoCircle color={'#faad14'} width={16} />
          <Space direction="vertical" size={0}>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
              You are currently on an outdated version of AbbyOS
            </Text>
            <Text type="secondary">Please refresh the page to get our latest updates!</Text>
          </Space>
        </Space>
      </AlertBanner>
    </Modal>
  )
}

const AlertBanner = styled.div`
  border: 1px solid #faad14;
  border-radius: 8px;
  background-color: #fffbe6;
  margin-top: 20px;
  padding: 8px;
`

export default CheckAppVersionUpdate
