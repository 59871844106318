import React, { useCallback } from 'react'
import { Select, SelectProps } from 'antd'
import debounce from 'lodash/debounce'
import { PatientData_GetPatientsByNameQuery } from 'generated/graphql'
import { useQuery } from 'hooks/useQuery'
import { formatName } from 'utils/names'
import { GET_PATIENTS_BY_NAME } from 'store/queries/patient.query'
import { useStore } from 'store'

const { Option } = Select

/**
 * SearchablePatient component allows users to search and select patients by name.
 */
const SearchablePatient = (props: SelectProps): React.ReactElement => {
  const { data, isLoading, reloadData } = useQuery<PatientData_GetPatientsByNameQuery>({
    query: GET_PATIENTS_BY_NAME,
    params: { name: 'name co ' }
  })

  const { update: updatePatientState } = useStore((state) => state.patient)
  // HandleSearch function
  const handleSearch = useCallback(
    debounce(async (searchText: string): Promise<void> => {
      const data = await reloadData({ name: 'name co ' + searchText })
      if (data.PatientList) {
        updatePatientState(data.PatientList)
      }
    }, 400),
    [reloadData]
  )

  return (
    <Select
      showSearch
      optionFilterProp="children"
      loading={isLoading}
      onSearch={(text) => handleSearch(text)}
      {...props}
    >
      {data?.PatientList?.map((option) => (
        <Option key={option?.id} value={option?.id}>
          {formatName((option?.name && option?.name[0]) || {})}
        </Option>
      ))}
    </Select>
  )
}

export default SearchablePatient
