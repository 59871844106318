import { MedplumClient, ResourceArray } from '@medplum/core'
import { Appointment, Encounter, HumanName, Patient, Practitioner, Task } from '@medplum/fhirtypes'
import * as Sentry from '@sentry/react'
import { chunk } from 'lodash'
import dayjs from 'dayjs'
import { Visit } from 'components/Patients/Visits/types'

import { APPOINTMENT_CHANGE_REASON_EXTENSION, AppointmentType } from 'fhir/Appointment/constants'
import {
  getCalculatedVisitStatusFromFragment,
  getCalculatedVisitStatus,
  getEncounterPractitionerRefsByRole,
  getEncounterPractitionersByRole,
  getScheduledEncounterPeriod
} from 'fhir/Encounter/helpers'
import {
  BaseAppointmentFragment,
  BaseEncounterWithAppointmentsFragment,
  GetPatientVisits_VisitFragment,
  GetPatientVisitsQuery
} from 'generated/graphql'
import { castToEnum } from 'utils/enum'
import { formatName, formatPrimaryName } from 'utils/names'
import { BasePatientFragment } from 'generated/graphql'
import { getValidatedFragment } from 'fhir/utils'
import { EncounterParticipantType } from 'fhir/Encounter/constants'
import { TaskCode } from 'fhir/Task/constants'
import { getPeriodWithTimezone } from 'fhir/Appointment/helpers'
import { extractCursorFromResponse, fetchWithCursor } from './fetch.helpers'

export const MAX_FETCH_COUNT = 1000

export type ParticipantIdsModel = {
  patientIds: string[]
  practitionerIds: string[]
}

export const parseAppointmentInfo = (
  appointment: Appointment
): {
  visitType: AppointmentType
  reasonForChange?: string
  participantIds: ParticipantIdsModel
} => {
  const visitType = appointment?.appointmentType?.coding?.[0]?.code

  if (!visitType) {
    throw new Error('Visit type is missing', { cause: appointment })
  }

  const reasonForChange = appointment?.extension?.find(
    (ext) => ext.url === APPOINTMENT_CHANGE_REASON_EXTENSION
  )?.valueCode

  const participantIds: ParticipantIdsModel = {
    patientIds: [],
    practitionerIds: []
  }
  appointment?.participant?.forEach((p) => {
    const participantRef = p.actor?.reference
    if (participantRef?.startsWith('Patient')) {
      participantIds.patientIds.push(participantRef.split('/')[1])
    }
    if (participantRef?.startsWith('Practitioner')) {
      participantIds.practitionerIds.push(participantRef.split('/')[1])
    }
  })

  return {
    visitType: castToEnum(AppointmentType, visitType) as AppointmentType,
    reasonForChange,
    participantIds
  }
}

export const transformQueryToVisits = (data: GetPatientVisitsQuery): Visit[] => {
  const encounters =
    data?.AppointmentList?.flatMap((appointment) => appointment?.EncounterList)?.filter(
      (e): e is GetPatientVisits_VisitFragment => !!e
    ) ?? []

  return encounters
    .map((e): Visit | undefined => {
      const appointments = data?.AppointmentList?.filter((a) => {
        const encounterAppointmentIds =
          e.appointment?.map((a) => a.reference?.split('/')[1] ?? null) ?? []
        return encounterAppointmentIds.includes(a?.id ?? null)
      })

      const encounterWithAppointment: BaseEncounterWithAppointmentsFragment = {
        ...e,
        appointment: appointments?.map((a) => ({
          reference: `Appointment/${a?.id}`,
          resource: a
        }))
      }
      const appointmentId = getValidatedFragment<BaseAppointmentFragment>(
        encounterWithAppointment.appointment?.at(0)?.resource
      )?.id
      if (!appointmentId) {
        Sentry.captureException(
          new Error('Appointment not found', {
            cause: {
              encounter: encounterWithAppointment
            }
          })
        )
        return
      }
      const period = getScheduledEncounterPeriod(encounterWithAppointment) ?? undefined
      const status = getCalculatedVisitStatusFromFragment(encounterWithAppointment)
      const patient = getValidatedFragment<BasePatientFragment>(
        encounterWithAppointment.subject?.resource
      )
      const patientId = patient?.id ?? null
      const patientName = patient?.name?.at(0)
      const formattedPatientName = patientName ? formatName(patientName as HumanName) : ''
      const caregivers = getEncounterPractitionersByRole(
        encounterWithAppointment,
        EncounterParticipantType.CAREGIVER
      )

      return {
        id: e.id ?? '',
        patientName: formattedPatientName ?? '',
        patientId,
        caregivers,
        status,
        appointmentId,
        period: {
          start: period?.start ?? null,
          end: period?.end ?? null
        }
      }
    })
    .filter((v): v is Visit => !!v)
}

export interface FetchAllVisitsParams {
  patient?: string
  _filter?: string
  status?: string
}

export const fetchAllVisits = async (
  medplumClient: MedplumClient,
  params: FetchAllVisitsParams
): Promise<Visit[]> => {
  const fetchVisitsWithCursor = async (
    cursor?: string
  ): Promise<{ cursor: string | null; results: Visit[] }> => {
    const appointmentsWithEncounterAndTask: ResourceArray<Appointment | Encounter | Task> =
      await medplumClient.searchResources('Appointment', {
        ...params,
        _revinclude: 'Encounter:appointment',
        _count: '300',
        _cursor: cursor,
        _sort: '_lastUpdated'
      })

    const appointments = appointmentsWithEncounterAndTask.filter(
      (resource): resource is Appointment => resource.resourceType === 'Appointment'
    )
    const encounters = appointmentsWithEncounterAndTask.filter(
      (resource): resource is Encounter => resource.resourceType === 'Encounter'
    )

    const chunkedEncounterReferences = chunk(encounters, 100)

    const qaTasks: Task[] = []
    await Promise.all(
      chunkedEncounterReferences.map(async (encounterReferencesChunk) => {
        const qaTasksForChunk = await medplumClient.searchResources('Task', {
          encounter: encounterReferencesChunk.map((e) => `Encounter/${e.id}`).join(','),
          code: TaskCode.CNA_VISIT_QA,
          _count: '1000'
        })

        qaTasks.push(...qaTasksForChunk)
      })
    )

    const practitionerRefsByEncounterRef: Record<string, string[]> = {}
    encounters.forEach((encounter) => {
      const encounterPractitionerRefs = getEncounterPractitionerRefsByRole(
        encounter,
        EncounterParticipantType.CAREGIVER
      )

      const encounterRef = `Encounter/${encounter.id}`
      practitionerRefsByEncounterRef[encounterRef] = encounterPractitionerRefs
    })

    const allEncounterPractitionerRefs = new Set(
      Object.values(practitionerRefsByEncounterRef).flat()
    )
    const chunkedEncounterPractitionerRefs = chunk(Array.from(allEncounterPractitionerRefs), 100)
    const practitioners: Practitioner[] = []
    await Promise.all(
      chunkedEncounterPractitionerRefs.map(async (practitionerRefChunk) => {
        const practitionersForChunk = await medplumClient.searchResources('Practitioner', {
          _id: practitionerRefChunk.join(','),
          _count: '1000'
        })

        practitioners.push(...practitionersForChunk)
      })
    )

    const patientRefs = new Set<string>(
      encounters
        .map((e) => e.subject?.reference)
        ?.filter((patientRef): patientRef is string => !!patientRef)
    )
    const chunkedPatientRefs = chunk(Array.from(patientRefs), 100)
    const patients: Patient[] = []
    await Promise.all(
      chunkedPatientRefs.map(async (patientRefChunk) => {
        const patientsForChunk = await medplumClient.searchResources('Patient', {
          'identifier:not': 'test-resource',
          _id: patientRefChunk.join(','),
          _count: '1000'
        })

        patients.push(...patientsForChunk)
      })
    )

    const cnaVisitsWithQATask: Visit[] = []
    encounters.forEach((encounter) => {
      const encounterRef = `Encounter/${encounter.id}`

      const relatedAppointment = appointments.find((a) =>
        encounter.appointment?.some(
          (appointment) => appointment.reference === `Appointment/${a.id}`
        )
      )
      if (!relatedAppointment) return

      const relatedVisitQaTask = qaTasks.find(
        (task) => task.encounter?.reference === `Encounter/${encounter.id}`
      )

      const encounterPractitionerRefs = practitionerRefsByEncounterRef[encounterRef]
      const relatedPractitioners = practitioners.filter((practitioner) =>
        encounterPractitionerRefs.includes(`Practitioner/${practitioner.id}`)
      )

      const relatedPatient = patients.find(
        (patient) => `Patient/${patient.id}` === encounter.subject?.reference
      )

      if (relatedPatient === undefined) {
        return
      }
      const visit: Visit = {
        id: encounter.id ?? '',
        patientName: formatPrimaryName(relatedPatient?.name) ?? '',
        patientId: relatedPatient?.id ?? null,
        caregivers: relatedPractitioners,
        status: getCalculatedVisitStatus(relatedAppointment, encounter, relatedVisitQaTask),
        appointmentId: relatedAppointment.id ?? '',
        period: getPeriodWithTimezone(relatedAppointment) ?? undefined,
        visitQaTaskId: relatedVisitQaTask?.id
      }

      cnaVisitsWithQATask.push(visit)
    })

    const newCursor = extractCursorFromResponse(appointmentsWithEncounterAndTask)

    return { cursor: newCursor, results: cnaVisitsWithQATask }
  }

  const visits = await fetchWithCursor<Visit>(fetchVisitsWithCursor)

  visits.sort((a, b) => {
    if (!a.period?.start) return 1
    if (!b.period?.start) return -1

    return dayjs(a.period?.start).valueOf() - dayjs(b.period?.start).valueOf()
  })

  return visits
}

export const fetchVisitsBySearchParams = async (
  medplum: MedplumClient,
  searchParams: {
    appointment?: any
    encounter?: any
    visitTask?: any
    visitQaTask?: any
  }
): Promise<{
  appointments: Appointment[]
  encounters: Encounter[]
  visitTasks: Task[]
  visitQaTasks: Task[]
}> => {
  const appointmentSearchParams = new URLSearchParams(searchParams.appointment)
  // 1. fetch appointments
  const appointments: Appointment[] = await medplum.searchResources(
    'Appointment',
    appointmentSearchParams
  )

  const appointmentIds: string[] = appointments.map((appt) => appt.id as string)
  const appointmentRefs = appointmentIds.map((id) => `Appointment/${id}`)

  // 2. fetch related encounters, batch encounterIds to 250 to avoid default URL length limit 16,384
  const encounters: Encounter[] = []
  for (let i = 0; i < appointmentRefs.length; i += 250) {
    const batchAppointmentRefs = appointmentRefs.slice(i, i + 250)
    const batchEncounters = await medplum.searchResources('Encounter', {
      _count: '1000',
      appointment: batchAppointmentRefs.join(',')
    })
    encounters.push(...batchEncounters)
  }

  const encounterIds: string[] = encounters.map((encounter) => encounter.id as string)
  const encounterRefs = encounterIds.map((id) => `Encounter/${id}`)

  // 3. fetch related CNA tasks
  const visitTasks: Task[] = []
  for (let i = 0; i < encounterRefs.length; i += 250) {
    const batchEncounterRefs = encounterRefs.slice(i, i + 250)
    const batchTasks = await medplum.searchResources('Task', {
      _count: '1000',
      encounter: batchEncounterRefs.join(','),
      ...(searchParams.visitTask ?? {})
    })
    visitTasks.push(...batchTasks)
  }

  // 4. fetch related Visit QA tasks
  const visitQaTasks: Task[] = []
  for (let i = 0; i < encounterRefs.length; i += 250) {
    const batchEncounterRefs = encounterRefs.slice(i, i + 250)
    const batchTasks = await medplum.searchResources('Task', {
      _count: '1000',
      encounter: batchEncounterRefs.join(','),
      ...(searchParams.visitQaTask ?? {})
    })
    visitQaTasks.push(...batchTasks)
  }

  return {
    appointments,
    encounters,
    visitTasks,
    visitQaTasks
  }
}
