export enum StateEnum {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
  NO_STATE_SELECTED = ''
}

export enum StateNameUppercaseForReports {
  COLORADO = 'COLORADO',
  PENNSYLVANIA = 'PENNSYLVANIA'
}

type StateOption = {
  value: StateEnum
  label: string
}

export const STATE_OPTIONS: StateOption[] = [
  { value: StateEnum.AL, label: 'Alabama' },
  { value: StateEnum.AK, label: 'Alaska' },
  { value: StateEnum.AZ, label: 'Arizona' },
  { value: StateEnum.AR, label: 'Arkansas' },
  { value: StateEnum.CA, label: 'California' },
  { value: StateEnum.CO, label: 'Colorado' },
  { value: StateEnum.CT, label: 'Connecticut' },
  { value: StateEnum.DE, label: 'Delaware' },
  { value: StateEnum.DC, label: 'District Of Columbia' },
  { value: StateEnum.FL, label: 'Florida' },
  { value: StateEnum.GA, label: 'Georgia' },
  { value: StateEnum.HI, label: 'Hawaii' },
  { value: StateEnum.ID, label: 'Idaho' },
  { value: StateEnum.IL, label: 'Illinois' },
  { value: StateEnum.IN, label: 'Indiana' },
  { value: StateEnum.IA, label: 'Iowa' },
  { value: StateEnum.KS, label: 'Kansas' },
  { value: StateEnum.KY, label: 'Kentucky' },
  { value: StateEnum.LA, label: 'Louisiana' },
  { value: StateEnum.ME, label: 'Maine' },
  { value: StateEnum.MD, label: 'Maryland' },
  { value: StateEnum.MA, label: 'Massachusetts' },
  { value: StateEnum.MI, label: 'Michigan' },
  { value: StateEnum.MN, label: 'Minnesota' },
  { value: StateEnum.MS, label: 'Mississippi' },
  { value: StateEnum.MO, label: 'Missouri' },
  { value: StateEnum.MT, label: 'Montana' },
  { value: StateEnum.NE, label: 'Nebraska' },
  { value: StateEnum.NV, label: 'Nevada' },
  { value: StateEnum.NH, label: 'New Hampshire' },
  { value: StateEnum.NJ, label: 'New Jersey' },
  { value: StateEnum.NM, label: 'New Mexico' },
  { value: StateEnum.NY, label: 'New York' },
  { value: StateEnum.NC, label: 'North Carolina' },
  { value: StateEnum.ND, label: 'North Dakota' },
  { value: StateEnum.OH, label: 'Ohio' },
  { value: StateEnum.OK, label: 'Oklahoma' },
  { value: StateEnum.OR, label: 'Oregon' },
  { value: StateEnum.PA, label: 'Pennsylvania' },
  { value: StateEnum.RI, label: 'Rhode Island' },
  { value: StateEnum.SC, label: 'South Carolina' },
  { value: StateEnum.SD, label: 'South Dakota' },
  { value: StateEnum.TN, label: 'Tennessee' },
  { value: StateEnum.TX, label: 'Texas' },
  { value: StateEnum.UT, label: 'Utah' },
  { value: StateEnum.VT, label: 'Vermont' },
  { value: StateEnum.VA, label: 'Virginia' },
  { value: StateEnum.WA, label: 'Washington' },
  { value: StateEnum.WV, label: 'West Virginia' },
  { value: StateEnum.WI, label: 'Wisconsin' },
  { value: StateEnum.WY, label: 'Wyoming' },
  { value: StateEnum.NO_STATE_SELECTED, label: 'No State Selected' }
]

export const STATE_OPTIONS_FOR_REPORTS: { value: string; label: string }[] = [
  { value: StateNameUppercaseForReports.COLORADO, label: 'Colorado' },
  { value: StateNameUppercaseForReports.PENNSYLVANIA, label: 'Pennsylvania' }
]
