import { StateCreator } from 'zustand'
import { MedplumClient } from '@medplum/core'
import {
  BasePractitionerFragment,
  BasePractitionerRoleFragment,
  Get_EmployeesQuery,
  PractitionerData_GetPractitionersByNameQuery
} from 'generated/graphql'
import { formatPrimaryName } from 'utils/names'
import { getPractitionerTimezone } from 'fhir/Practitioner/helpers'
import { captureException, SentrySources } from 'utils/sentry'
import { getErrorInstance } from 'utils/helpers'
import {
  GET_PRACTITIONER_DATA_PRACTITIONER,
  GET_PRACTITIONER_DATA_PRACTITIONERS_LIST
} from './queries/practitioner.query'
import { RootSlice } from './rootType'

type PractitionerInfo = {
  formattedPrimaryName: string | null
  timezone?: string
  resourceType: 'Practitioner'
}
export type PractitionerWithRoleInfoSchema =
  | ({
      PractitionerRoleList?: Array<BasePractitionerRoleFragment | null> | null
    } & BasePractitionerFragment &
      PractitionerInfo)
  | null

export type PractitionerListSchema = Array<PractitionerWithRoleInfoSchema>

export interface PractitionerSlice {
  practitioner: {
    list: PractitionerListSchema
    idToPractitionerMapping: Record<string, PractitionerWithRoleInfoSchema>
    isLoading: boolean
    getPractitioner: (
      medplum: MedplumClient,
      id: string
    ) => Promise<PractitionerWithRoleInfoSchema | null>
    loadAllPractitioners: (medplum: MedplumClient, count?: number) => Promise<void>
    errorMessage: object | string | null
    update: (
      practitioners: PractitionerData_GetPractitionersByNameQuery['PractitionerList']
    ) => void
  }
}

export const createPractitionerSlice: StateCreator<
  RootSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PractitionerSlice
> = (set) => ({
  practitioner: {
    list: [],
    idToPractitionerMapping: {},
    isLoading: false,
    errorMessage: null,
    getPractitioner: async (medplum, id) => {
      try {
        set((state) => {
          state.practitioner.errorMessage = null
          state.practitioner.isLoading = true
        })

        const { data } = await medplum.graphql(GET_PRACTITIONER_DATA_PRACTITIONER, null, {
          id
        })

        const practitioner = data.Practitioner

        if (practitioner) {
          const formattedPrimaryName = formatPrimaryName(practitioner?.name)
          const timezone = getPractitionerTimezone(practitioner)
          const currObject = {
            ...practitioner,
            formattedPrimaryName,
            timezoneExtension: undefined,
            timezone
          }

          set((state) => {
            state.practitioner.idToPractitionerMapping[id] = currObject
            state.practitioner.isLoading = false
          })
        }

        return null
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.PRACTITIONER_SLICE },
          extras: { params: { id } }
        })
        set((state) => {
          state.practitioner.errorMessage = error?.message || 'Error getting practitioner data.'
          state.practitioner.isLoading = false
        })

        return null
      }
    },
    loadAllPractitioners: async (medplum, count = 1000) => {
      set((state) => {
        state.practitioner.errorMessage = null
        state.practitioner.isLoading = true
      })
      try {
        let offset = 0
        const practitionerList: Get_EmployeesQuery['PractitionerList'] = []
        let currPractitionerList: Get_EmployeesQuery['PractitionerList'] = []

        // query all practitioners since there's no performance issue with the current data size
        while (offset === 0 || currPractitionerList?.length === count) {
          const { data, errors } = await medplum.graphql(
            GET_PRACTITIONER_DATA_PRACTITIONERS_LIST,
            null,
            {
              count,
              offset
            }
          )
          currPractitionerList = data.PractitionerList
          practitionerList.push(...(currPractitionerList ?? []))
          offset += count

          if (errors) {
            set((state) => {
              state.practitioner.errorMessage = Array.isArray(state.practitioner.errorMessage)
                ? [...state.practitioner.errorMessage, ...errors]
                : errors
            })
          }
        }

        const parsedPractitionerList = practitionerList.reduce((acc, curr) => {
          const formattedPrimaryName = formatPrimaryName(curr?.name)
          const timezone = getPractitionerTimezone(curr)
          const currObject = {
            ...curr,
            formattedPrimaryName,
            timezoneExtension: undefined,
            timezone
          }
          acc.push(currObject as PractitionerWithRoleInfoSchema)
          return acc
        }, [] as PractitionerListSchema)

        const idToPractitionerMapping = parsedPractitionerList.reduce((acc, curr) => {
          if (curr?.id) {
            acc[curr.id] = curr
          }
          return acc
        }, {} as Record<string, PractitionerWithRoleInfoSchema>)

        set((state) => {
          if (practitionerList.length) {
            state.practitioner.list = parsedPractitionerList
            state.practitioner.idToPractitionerMapping = idToPractitionerMapping
          }
        })
      } catch (error) {
        set((state) => {
          state.practitioner.errorMessage = error?.message || 'Error getting practitioner data.'
        })
      } finally {
        set((state) => {
          state.practitioner.isLoading = false
        })
      }
    },
    update: (practitioners: PractitionerData_GetPractitionersByNameQuery['PractitionerList']) => {
      if (!practitioners) return
      const parsedMapping = practitioners.reduce((acc, curr) => {
        if (!curr) return acc
        const formattedPrimaryName = formatPrimaryName(curr?.name)
        const timezone = getPractitionerTimezone(curr)
        const parsedPractitioner = {
          ...curr,
          formattedPrimaryName,
          timezoneExtension: undefined,
          timezone
        }
        acc[parsedPractitioner.id as string] = parsedPractitioner
        return acc
      }, {})

      set((state) => {
        state.practitioner.idToPractitionerMapping = {
          ...parsedMapping
        }
      })
    }
  }
})
