import { Dayjs } from 'dayjs'
import { CalculatedVisitStatus } from 'fhir/Encounter/constants'

export interface SelectedEvent {
  appointmentId: string
  encounterId?: string
  visitQaTaskId?: string
  cnaVisitTaskIds?: string[]
  status: boolean
}

export enum AppointmentActor {
  PRACTITIONER = 'practitioner',
  PATIENT = 'patient'
}

export type ScheduleFormType = {
  appointmentTime: Date[]
  caregiverRef: string
  visitStatus: CalculatedVisitStatus
  throughDate: Dayjs | null
  changeReason?: string
  notes: string
}
