import { HumanName as MedplumHumanName } from '@medplum/fhirtypes'
import { formatHumanName } from '@medplum/core'
import { HumanName as GraphQlHumanName } from 'generated/graphql'

export const getPrimaryName = <T extends GraphQlHumanName | MedplumHumanName>(
  names: T[],
  type?: MedplumHumanName['use']
): T | null => {
  if (type) {
    return names.find((name) => name.use === type) ?? null
  }

  // Empty string is used to denote no type specified, it can essentially be considered a "default"
  // value.
  const typeHierarchy = ['official', 'usual', 'nickname', '', 'maiden', 'anonymous', 'temp', 'old']
  // Array.prototype.sort() sorts the array `in-place`, so we need to create a new array to avoid mutating the original.
  const sortedNames = [...names].sort(
    (a, b) => typeHierarchy.indexOf(a.use ?? '') - typeHierarchy.indexOf(b.use ?? '')
  )

  return sortedNames[0] ?? null
}

export const formatName = <T extends GraphQlHumanName | MedplumHumanName>(
  name: T | null,
  options?: {
    lastFirst?: boolean
    includeSuffix?: boolean
  }
): string | null => {
  if (!name) return null

  if (options?.lastFirst) {
    const firstGivenName = name.given?.[0]
    let lastName = name.family
    let firstName = firstGivenName
    // TODO: Fix data validation issue where family name can exist in the given name
    if (!lastName && firstGivenName?.includes(' ')) {
      lastName = firstGivenName?.split(' ')?.pop()
      firstName = firstGivenName?.split(' ')?.shift()
    }
    const nameString = firstName && lastName ? `${lastName}, ${firstName}` : lastName ?? firstName

    if (!nameString) return null

    const suffixText = name.suffix?.join(', ')
    return `${nameString}${suffixText && options.includeSuffix ? `, ${suffixText}` : ''}`
  } else if (name.given && name.given.length > 0 && name.family) {
    return formatHumanName(name as MedplumHumanName, { suffix: options?.includeSuffix })
  } else if (name.text) {
    return name.text
  }

  return null
}

export const formatPrimaryName = <T extends GraphQlHumanName | MedplumHumanName>(
  names?: string | T[] | null,
  options?: {
    type?: MedplumHumanName['use']
    lastFirst?: boolean
    includeSuffix?: boolean
  }
): string | null => {
  const nameIsArray = Array.isArray(names)
  const primaryName = nameIsArray ? getPrimaryName(names ?? [], options?.type) : names
  return nameIsArray ? formatName(primaryName as T, options) : (primaryName as string)
}

export const parseFullName = (fullName: string): MedplumHumanName => {
  fullName = fullName.trim()
  let firstName = ''
  let family = ''
  let suffix: string | null = null
  let fullNameNoSuffix = fullName

  // Check if there's a comma (indicating a suffix)
  const commaIndex = fullName.indexOf(',')

  if (commaIndex !== -1) {
    suffix = fullName.slice(commaIndex + 1).trim()
    fullNameNoSuffix = fullName.slice(0, commaIndex).trim()
  }

  // Split the remaining string by space
  const parts = fullNameNoSuffix.split(/\s+/).filter(Boolean) // Filters out empty strings

  if (parts.length > 1) {
    // Assume the last part is the last name
    family = parts.pop() as string
    firstName = parts.join(' ') // Remaining parts are first name(s)
  } else {
    throw new Error('Family name is required') // Require both first and last names
  }

  // Append suffix if present
  if (suffix) {
    family += `, ${suffix}`
  }

  return {
    family: family,
    given: firstName.split(' ')
  }
}
