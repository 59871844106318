import dayjs, { Dayjs } from 'dayjs'
import { Appointment, Encounter, Task } from '@medplum/fhirtypes'
import { getValidatedFragment } from 'fhir/utils'
import {
  BaseAppointmentFragment,
  BaseEncounterFragment,
  BaseEncounterParticipantFragment,
  BaseEncounterWithAppointmentsAndVisitQaTasksFragment,
  BaseEncounterWithAppointmentsFragment,
  BasePractitionerFragment,
  BaseTaskFragment
} from 'generated/graphql'
import { castToEnum } from 'utils/enum'
import { CNAVisitQAOutcome, TASK_VISIT_QA_OUTCOME_CODE_SYSTEM } from 'fhir/Task/constants'
import { getPeriodWithTimezone } from 'fhir/Appointment/helpers'
import {
  CalculatedVisitStatus,
  ENCOUNTER_PARTICIPANT_TYPE_CODE_SYSTEM,
  EncounterParticipantType,
  EncounterClassCode,
  EncounterStatus
} from './constants'

export const getEncounterParticipantsByRole = <
  T extends BaseEncounterFragment,
  G extends BaseEncounterParticipantFragment
>(
  encounter: T,
  role: EncounterParticipantType
): G[] => {
  const matchingParticipants =
    encounter.participant?.filter((participant) =>
      participant.type?.some((type) =>
        type.coding?.some(
          (coding) =>
            coding.system === ENCOUNTER_PARTICIPANT_TYPE_CODE_SYSTEM && coding.code === role
        )
      )
    ) ?? []

  return matchingParticipants as G[]
}

export const getEncounterPractitionersByRole = <
  T extends BaseEncounterFragment,
  G extends BasePractitionerFragment
>(
  encounter: T,
  role: EncounterParticipantType
): G[] => {
  const matchingParticipants = getEncounterParticipantsByRole(encounter, role)

  return matchingParticipants
    .map((participant) =>
      getValidatedFragment<BasePractitionerFragment>(participant.individual?.resource)
    )
    .filter((practitioner): practitioner is G => !!practitioner)
}

export const getEncounterPractitionerRefsByRole = (
  encounter: Encounter,
  role: EncounterParticipantType
): string[] => {
  const matchingParticipantsWithRole =
    encounter.participant?.filter((participant) =>
      participant.type?.some((type) =>
        type.coding?.some(
          (coding) =>
            coding.system === ENCOUNTER_PARTICIPANT_TYPE_CODE_SYSTEM && coding.code === role
        )
      )
    ) ?? []

  const matchingPractitionerRefs = matchingParticipantsWithRole
    .map((participant) => participant.individual?.reference)
    ?.filter((ref): ref is string => !!ref)

  return matchingPractitionerRefs
}

export const getActualEncounterPeriod = (
  encounter: BaseEncounterFragment,
  timezone?: string
): { start: Dayjs; end: Dayjs } | null => {
  const start = encounter.period?.start
  const end = encounter.period?.end
  if (!start || !end) return null

  if (timezone) {
    return {
      start: dayjs(start).tz(timezone),
      end: dayjs(end).tz(timezone)
    }
  }

  return {
    start: dayjs(start),
    end: dayjs(end)
  }
}

export const getScheduledEncounterPeriod = (
  encounter: BaseEncounterWithAppointmentsFragment,
  timezone?: string
): { start: Dayjs; end: Dayjs } | null => {
  const appointment = getValidatedFragment<BaseAppointmentFragment>(
    encounter.appointment?.at(0)?.resource
  )

  return appointment ? getPeriodWithTimezone(appointment as Appointment, timezone) : null
}

export const visitIsCharted = (visit: BaseEncounterFragment): boolean => {
  const visitStatus = visit.status

  return visitStatus === EncounterStatus.FINISHED
}

export const getCalculatedVisitStatusFromFragment = (
  visit: BaseEncounterWithAppointmentsAndVisitQaTasksFragment
): CalculatedVisitStatus => {
  const task = visit.visitQATasks?.at(0) ?? undefined
  const encounterStatus = visit.status as EncounterStatus
  const encounterClassCode = visit.class?.code as EncounterClassCode
  const { start: scheduledStart, end: scheduledEnd } = getScheduledEncounterPeriod(visit) ?? {}
  return calculateVisitStatus(
    encounterStatus,
    encounterClassCode,
    getVisitQATaskStatus(task),
    scheduledStart,
    scheduledEnd
  )
}

export const getCalculatedVisitStatus = (
  appointment: Appointment,
  encounter: Encounter,
  task?: Task
): CalculatedVisitStatus => {
  const encounterStatus = encounter.status as EncounterStatus
  const encounterClassCode = encounter.class?.code as EncounterClassCode
  const { start: scheduledStart, end: scheduledEnd } =
    getScheduledAppointmentPeriod(appointment) ?? {}
  return calculateVisitStatus(
    encounterStatus,
    encounterClassCode,
    getVisitQATaskStatus(task),
    scheduledStart,
    scheduledEnd
  )
}

function calculateVisitStatus(
  encounterStatus: EncounterStatus,
  encounterClassCode: EncounterClassCode,
  visitQAStatus?: CNAVisitQAOutcome,
  scheduledStart?: dayjs.Dayjs | string,
  scheduledEnd?: dayjs.Dayjs | string
): CalculatedVisitStatus {
  switch (encounterStatus) {
    case EncounterStatus.CANCELLED:
      return CalculatedVisitStatus.MISSED
    case EncounterStatus.ON_LEAVE:
      return CalculatedVisitStatus.HOLD
    case EncounterStatus.FINISHED:
      if (visitQAStatus === CNAVisitQAOutcome.APPROVED) {
        return CalculatedVisitStatus.APPROVED
      } else if (visitQAStatus === CNAVisitQAOutcome.DENIED) {
        return CalculatedVisitStatus.DENIED
      } else {
        return CalculatedVisitStatus.SUBMITTED
      }
    case EncounterStatus.PLANNED:
      const isOverTwoWeeksOld = dayjs
        .utc(scheduledEnd)
        .isBefore(dayjs.utc().subtract(14, 'day'), 'day')
      if (isOverTwoWeeksOld) {
        return CalculatedVisitStatus.HOLD
      }

      const isPastToday = dayjs.utc(scheduledStart).isBefore(dayjs.utc(), 'day')
      if (isPastToday) {
        return encounterClassCode === EncounterClassCode.HHA_STANDARD_SHIFT
          ? CalculatedVisitStatus.HOLD
          : CalculatedVisitStatus.LATE
      }

      const isPastCurrentTime = dayjs.utc(scheduledEnd).isBefore(dayjs.utc())
      if (isPastCurrentTime && encounterClassCode === EncounterClassCode.HHA_STANDARD_SHIFT) {
        return CalculatedVisitStatus.LATE
      }

      return CalculatedVisitStatus.SCHEDULED
    default:
      return CalculatedVisitStatus.UNKNOWN
  }
}

export const getVisitQATaskStatus = (
  task: Task | BaseTaskFragment | undefined
): CNAVisitQAOutcome | undefined => {
  const visitQAOutcome = task?.businessStatus?.coding?.find(
    (c) => c.system === TASK_VISIT_QA_OUTCOME_CODE_SYSTEM
  )?.code
  return visitQAOutcome ? castToEnum(CNAVisitQAOutcome, visitQAOutcome) : undefined
}

export const getScheduledAppointmentPeriod = (
  appointment: Appointment
): { start: string; end: string } | null => {
  const appointmentStart = appointment?.start
  const appointmentEnd = appointment?.end
  if (!appointmentStart || !appointmentEnd) return null

  return {
    start: appointmentStart,
    end: appointmentEnd
  }
}
