import { StateCreator } from 'zustand'
import { MedplumClient } from '@medplum/core'
import { capitalize } from 'lodash'
import * as Sentry from '@sentry/react'
import { Appointment } from '@medplum/fhirtypes'
import { Visit } from 'components/Patients/Visits/types'
import { GET_CAREGIVER_VISIT } from 'hooks/Encounter/getCaregiverVisit.query'
import { VISIT_QA_TASK_CODES, VisitTaskIdentifiers } from 'fhir/Task/constants'

import { AppointmentActor } from 'components/Schedule/utils/types'
import { getCalculatedVisitStatus } from 'fhir/Encounter/helpers'
import { AppointmentStatus, AppointmentType, GeneralRNVisitType } from 'fhir/Appointment/constants'
import { CalculatedVisitStatus as CNAVisitStatus } from 'fhir/Encounter/constants'
import { GetCnaVisit_VisitFragment, GetCnaVisitQuery } from 'generated/graphql'
import { captureException, SentrySources } from 'utils/sentry'
import { getErrorInstance } from 'utils/helpers'
import {
  fetchAllVisits,
  FetchAllVisitsParams,
  fetchVisitsBySearchParams,
  parseAppointmentInfo,
  ParticipantIdsModel
} from './helpers/visit.helpers'
import { RootSlice } from './rootType'
import { GET_PATIENTS_BY_IDS } from './queries/patient.query'
import { GET_PRACTITIONERS_BY_IDS } from './queries/practitioner.query'

export type VisitSchema = {
  visitType: AppointmentType
  appointmentId: string
  encounterId?: string
  episodeOfCareId?: string
  taskId?: string
  cnaVisitTaskIds?: string[]
  status: CNAVisitStatus | GeneralRNVisitType
  start: string
  end: string
  reasonForChange?: string
  participantIds: ParticipantIdsModel
  notes: string
}

export type updateVisitsInfoParams = {
  appointmentId: string
  info: {
    status?: CNAVisitStatus | GeneralRNVisitType
    start?: string
    end?: string
    reasonForChange?: string
    notes?: string
    participantIds?: ParticipantIdsModel
  }
}[]

export type LoadVisitsQueryParams = {
  patientId?: string
  startDate?: string
  endDate?: string
  statuses?: AppointmentStatus[]
}

export type VisitAndParams = {
  params: FetchAllVisitsParams
  visits: Visit[]
}

export interface VisitSlice {
  visit: {
    isLoading: boolean
    error: string | null
    list: Record<string, VisitAndParams>
    byId: Record<string, GetCnaVisit_VisitFragment>
    appointmentIdToVisitMapping: Record<string, VisitSchema>
    actorIdToAppointmentIds: {
      [AppointmentActor.PRACTITIONER]: Record<string, string[]>
      [AppointmentActor.PATIENT]: Record<string, string[]>
    }

    loadPaginatedVisits: (
      medplum: MedplumClient,
      appointmentActor: AppointmentActor,
      appointmentActorId: string,
      startDate: string,
      endDate: string,
      statuses: AppointmentStatus[]
    ) => Promise<void>
    updateVisitsInfo: (params: updateVisitsInfoParams) => void
    deleteVisits: (appointmentIds: string[]) => void
    loadVisit: (medplum: MedplumClient, visitId: string) => Promise<void>
    loadVisits: (medplum: MedplumClient, params: LoadVisitsQueryParams) => Promise<Visit[]>
  }
}

const defaultState: Pick<
  VisitSlice['visit'],
  | 'list'
  | 'byId'
  | 'isLoading'
  | 'error'
  | 'actorIdToAppointmentIds'
  | 'appointmentIdToVisitMapping'
> = {
  list: {},
  byId: {},
  actorIdToAppointmentIds: {
    [AppointmentActor.PRACTITIONER]: {},
    [AppointmentActor.PATIENT]: {}
  },
  appointmentIdToVisitMapping: {},
  isLoading: false,
  error: null
}

export const createVisitSlice: StateCreator<
  RootSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  VisitSlice
> = (set, get) => ({
  visit: {
    ...defaultState,
    updateVisitsInfo: (params: updateVisitsInfoParams) => {
      set((state) => {
        params.forEach(({ appointmentId, info }) => {
          const visit = state.visit.appointmentIdToVisitMapping[appointmentId]
          if (!visit) {
            return
          }
          const { status, start, end, reasonForChange, participantIds, notes } = info
          if (status) {
            visit.status = status
          }
          if (start) {
            visit.start = start
          }
          if (end) {
            visit.end = end
          }
          if (reasonForChange) {
            visit.reasonForChange = reasonForChange
          }
          if (notes) {
            visit.notes = notes
          }
          if (participantIds) {
            visit.participantIds.practitionerIds = participantIds.practitionerIds
          }
        })
      })
    },
    deleteVisits: (appointmentIds: string[]) => {
      set((state) => {
        appointmentIds.forEach((appointmentId) => {
          const appointment = state.visit.appointmentIdToVisitMapping[appointmentId]
          const practitionerParticipantIds = appointment?.participantIds?.practitionerIds ?? []
          practitionerParticipantIds.forEach((id) => {
            const appointmentIdsForPractitioner: string[] =
              state.visit.actorIdToAppointmentIds[AppointmentActor.PRACTITIONER][id] ?? []

            state.visit.actorIdToAppointmentIds[AppointmentActor.PRACTITIONER][id] =
              appointmentIdsForPractitioner.filter((id) => id !== appointmentId)
          })

          const patientParticipantIds = appointment?.participantIds?.patientIds ?? []
          patientParticipantIds.forEach((id) => {
            const appointmentIdsForPatient: string[] =
              state.visit.actorIdToAppointmentIds[AppointmentActor.PATIENT][id] ?? []

            state.visit.actorIdToAppointmentIds[AppointmentActor.PATIENT][id] =
              appointmentIdsForPatient.filter((id) => id !== appointmentId)
          })

          delete state.visit.appointmentIdToVisitMapping[appointmentId]
        })
      })
    },
    loadPaginatedVisits: async (
      medplum,
      appointmentActor,
      appointmentActorId,
      startDate,
      endDate,
      statuses
    ): Promise<void> => {
      set((state) => {
        state.visit.error = null
        state.visit.isLoading = true
      })
      try {
        if (!appointmentActor) {
          throw new Error('Invalid role')
        }
        const actorRef = `${capitalize(appointmentActor)}/${appointmentActorId}`
        const appointmentSearchParams = {
          _sort: '-_lastUpdated',
          _count: '1000',
          actor: actorRef,
          _filter: `date ge ${startDate} and date le ${endDate}`,
          status: statuses.join(',')
        }
        const { appointments, encounters, visitQaTasks, visitTasks } =
          await fetchVisitsBySearchParams(medplum, {
            appointment: appointmentSearchParams,
            visitQaTask: {
              code: VISIT_QA_TASK_CODES.join(',')
            },
            visitTask: {
              identifier: VisitTaskIdentifiers.join(',')
            }
          })

        const idToAppointmentMapping = appointments.reduce((acc, appointment) => {
          acc[appointment.id as string] = appointment
          return acc
        }, {} as Record<string, Appointment>)
        const appointmentIds: string[] = appointments.map((appt) => appt.id as string)
        const appointmentIdSet = new Set(appointmentIds)
        let patientIdSet = new Set<string>()
        let practitionerIdSet = new Set<string>()
        const appointmentMapping = encounters.reduce((acc, encounter) => {
          if (!encounter.appointment?.[0].reference) {
            return acc
          }
          const appointmentId = encounter.appointment[0].reference.split('/')[1]
          if (!appointmentIdSet.has(appointmentId)) {
            Sentry.captureException(
              new Error('Appointment not found', {
                cause: {
                  appointment: appointmentId
                }
              })
            )
            return acc
          }
          const currAppointment = idToAppointmentMapping[appointmentId]
          const currEncounterRef = `Encounter/${encounter?.id}`
          const currTask = visitQaTasks.find((task) =>
            Boolean(task?.encounter?.reference === currEncounterRef)
          )
          const encounterVisitTasks = visitTasks.filter((task) =>
            Boolean(task?.encounter?.reference === currEncounterRef)
          )

          const visitStatus = getCalculatedVisitStatus(currAppointment, encounter, currTask)
          if (!visitStatus) {
            throw new Error('Visit status is missing', {
              cause: { appointment: currAppointment, encounter, task: currTask }
            })
          }
          const { visitType, reasonForChange, participantIds } =
            parseAppointmentInfo(currAppointment)

          patientIdSet = patientIdSet.union(new Set(participantIds.patientIds))
          practitionerIdSet = practitionerIdSet.union(new Set(participantIds.practitionerIds))

          acc[currAppointment.id as string] = {
            visitType,
            appointmentId: currAppointment.id as string,
            encounterId: encounter.id as string,
            episodeOfCareId: encounter.episodeOfCare?.[0]?.reference?.split('/')[1] as string,
            taskId: currTask?.id,
            cnaVisitTaskIds: encounterVisitTasks.map((task) => task.id as string),
            status: visitStatus,
            start: currAppointment.start as string,
            end: currAppointment.end as string,
            reasonForChange,
            participantIds,
            notes: currAppointment.comment ?? ''
          }
          appointmentIdSet.delete(appointmentId)
          return acc
        }, {} as Record<string, VisitSchema>)

        const store = get()
        const patientResults = await medplum.graphql(GET_PATIENTS_BY_IDS, null, {
          ids: Array.from(patientIdSet).join(',')
        })
        if (patientResults?.data?.PatientList?.length > 0) {
          store.patient.update(patientResults?.data?.PatientList)
        }

        const practitionerResults = await medplum.graphql(GET_PRACTITIONERS_BY_IDS, null, {
          ids: Array.from(practitionerIdSet).join(',')
        })
        if (practitionerResults?.data?.PractitionerList?.length > 0) {
          store.practitioner.update(practitionerResults?.data?.PractitionerList)
        }

        // add RN visits to the mapping
        const appointmentIdsLeft = Array.from(appointmentIdSet)
        appointmentIdsLeft.forEach((id) => {
          const currAppointment = idToAppointmentMapping[id]
          const { visitType, reasonForChange, participantIds } =
            parseAppointmentInfo(currAppointment)

          appointmentMapping[id as string] = {
            visitType,
            appointmentId: currAppointment.id as string,
            encounterId: undefined,
            episodeOfCareId: undefined,
            taskId: undefined,
            cnaVisitTaskIds: [],
            status: GeneralRNVisitType.ASSESSMENT,
            start: currAppointment.start as string,
            end: currAppointment.end as string,
            reasonForChange,
            participantIds,
            notes: currAppointment.comment ?? ''
          }
        })

        if (appointmentActor) {
          set((state) => {
            const appointmentIdSet = state.visit.actorIdToAppointmentIds[appointmentActor][
              appointmentActorId
            ]
              ? new Set([
                  ...state.visit.actorIdToAppointmentIds[appointmentActor][appointmentActorId],
                  ...appointmentIds
                ])
              : new Set(appointmentIds)

            state.visit.actorIdToAppointmentIds[appointmentActor][appointmentActorId] =
              Array.from(appointmentIdSet)

            state.visit.appointmentIdToVisitMapping = {
              ...state.visit.appointmentIdToVisitMapping,
              ...appointmentMapping
            }
          })
        }
      } catch (error) {
        set((state) => {
          state.visit.error = error?.message || 'Error getting visit data.'
        })
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.VISITS },
          extras: { params: { appointmentActor, appointmentActorId, startDate, endDate, statuses } }
        })
      } finally {
        set((state) => {
          state.visit.isLoading = false
        })
      }
    },
    loadVisit: async (medplum: MedplumClient, visitId: string): Promise<void> => {
      const store = get()
      if (visitId in store.visit.list) return

      try {
        set((state) => {
          state.visit.isLoading = true
          state.visit.error = null
        })

        const { data: result, errors }: { data: GetCnaVisitQuery; errors: any } =
          await medplum.graphql(GET_CAREGIVER_VISIT, null, { visitId })

        if (errors) {
          throw new Error(errors)
        }

        const visit = result?.Encounter

        set((state) => {
          if (visit) {
            state.visit.byId[visitId] = visit
          }
          state.visit.error = null
        })
      } catch (error) {
        set((state) => {
          state.visit.error = error
        })

        throw error
      } finally {
        set((state) => {
          state.visit.isLoading = false
        })
      }
    },
    loadVisits: async (
      medplum: MedplumClient,
      { patientId, startDate, endDate, statuses }: LoadVisitsQueryParams
    ): Promise<Visit[]> => {
      const store = get()

      let visits: Visit[] = []
      const params: FetchAllVisitsParams = {}
      if (patientId) {
        params.patient = `Patient/${patientId}`
      }
      if (startDate && endDate) {
        params._filter = `date ge '${startDate}' and date le '${endDate}'`
      }
      if (statuses) {
        params.status = statuses.join(',')
      }

      try {
        if (
          patientId &&
          patientId in store.visit.list &&
          params._filter == store.visit.list[patientId].params?._filter &&
          params.status == store.visit.list[patientId].params?.status
        ) {
          visits = store.visit.list[patientId].visits
        } else {
          set((state) => {
            state.visit.isLoading = true
            state.visit.error = null
          })

          const visits = await fetchAllVisits(medplum, params)

          set((state) => {
            state.visit.list = {
              ...state.visit.list,
              [patientId ?? '']: {
                params,
                visits
              }
            }
            state.visit.error = null
          })
        }
        return visits
      } catch (error) {
        set((state) => {
          state.visit.error = error
        })
        throw error
      } finally {
        set((state) => {
          state.visit.isLoading = false
        })
      }
    }
  }
})
