import { Organization, Patient, Reference } from '@medplum/fhirtypes'
import dayjs, { Dayjs } from 'dayjs'
import {
  CONSENT_EPISODE_OF_CARE_EXTENSION_URL,
  CONSENT_TYPE_CODE_SYSTEM,
  ConsentStatus,
  ConsentTypeCode
} from 'fhir/Consent/constants'
import { getAuthorizationStatusFromProvisionRule } from 'fhir/Consent/helpers'
import { InsurancePlanType } from 'fhir/Coverage/constants'
import { EpisodeOfCareStatusEnum } from 'fhir/EpisodeofCare/constants'
import {
  getEpisodeOfCareStatusFromStatusHistory,
  sortCertPeriods
} from 'fhir/EpisodeofCare/helpers'
import { findExtension } from 'fhir/utils'
import {
  BaseConsentFragment,
  BaseCoverageFragment,
  BaseEpisodeOfCareFragment,
  BaseOrganizationFragment,
  BasePatientFragment,
  BasePatientWithAllergyFragment,
  BasePatientWithConsentFragment,
  BasePatientWithCoverageFragment,
  BasePatientWithDeviceFragment,
  BasePatientWithEpisodeOfCareAndConsentFragment,
  GetDefaultSocAnswersQuery,
  PatientContact
} from 'generated/graphql'
import { getCoverageByType } from 'utils/coverage'
import { transformToTimezoneOrUtc } from 'utils/dates'
import { UserError } from 'utils/userError'

export interface RnInfo {
  id: string
  display: string
}

const isString = (value: any): value is string => typeof value === 'string'

export const getPatientAllergies = (
  patientWithAllergies: BasePatientWithAllergyFragment
): string[] | null =>
  patientWithAllergies.AllergyIntoleranceList?.map((allergy) => {
    return allergy?.code?.coding?.[0]?.display
  }).filter(isString) || null

type PatientIdentifiers = 'ssn' | 'mrn'

export const getPatientIdentifiers = (
  patientData: BasePatientFragment
): Record<PatientIdentifiers, string | null | undefined> | undefined => {
  if (patientData) {
    const ssn = patientData.identifier?.find((id) => id?.type?.coding?.[0]?.code === 'SS')
    const mrn = patientData.identifier?.find((id) => id?.type?.coding?.[0]?.code === 'MR')

    return {
      ssn: ssn?.value,
      mrn: mrn?.value
    }
  }
}

export const getPatientDevices = (patientData: BasePatientWithDeviceFragment): string[] | null =>
  (patientData &&
    patientData.DeviceList?.map((device) => {
      return device?.type?.coding?.[0]?.display
    }).filter(isString)) ||
  null

export const getPrimaryCareProvider = (
  generalPractitioner: Patient['generalPractitioner']
): BaseOrganizationFragment | null => {
  const primaryOrganization = generalPractitioner?.find(
    (r) => r?.resource?.resourceType === 'Organization'
  )?.resource as BaseOrganizationFragment
  return primaryOrganization
}

export const getMedicaidCoverage = (
  patientData: BasePatientWithCoverageFragment | GetDefaultSocAnswersQuery['Patient']
): BaseCoverageFragment | null => {
  return getCoverageByType(patientData?.CoverageList, InsurancePlanType.Medicaid)
}

export const getMedicaidId = (
  patientData: BasePatientWithCoverageFragment | GetDefaultSocAnswersQuery['Patient']
): string | null => {
  return getMedicaidCoverage(patientData)?.subscriberId ?? null
}

export const getPrimaryLanguage = (patientData: BasePatientFragment): string | null => {
  const preferredCommunication = patientData.communication?.find((comm) => comm.preferred)
  const primaryLanguage = preferredCommunication?.language?.coding?.[0]
  return primaryLanguage?.display ?? null
}

export const getPatientSex = (
  patientData: BasePatientFragment | GetDefaultSocAnswersQuery['Patient']
): string | null => {
  const patientSexCode = patientData?.birthSexExtension
    ?.flatMap((e) => e?.valueCodeableConcept?.coding?.map((c) => c?.code))
    ?.find((code) => !!code)

  return patientSexCode ?? null
}

export const getPatientContactWithRelationship = (
  patientData: BasePatientFragment | undefined,
  relationship: string
): PatientContact | undefined => {
  const { contact } = patientData ?? {}

  const emergencyContact = contact?.find((el) =>
    el.relationship?.some((rel) => rel.text === relationship)
  )
  return emergencyContact
}

export type BaseEpisodeOfCareWithConsentFragment = BaseEpisodeOfCareFragment & {
  consent?: BaseConsentFragment
}

export const getPatientCertPeriods = (
  patient: BasePatientWithEpisodeOfCareAndConsentFragment
): BaseEpisodeOfCareWithConsentFragment[] => {
  const certPeriodConsents =
    patient?.ConsentList?.filter(
      (c): c is BaseConsentFragment =>
        !!c?.category?.find((code) =>
          code.coding?.some(
            (c) => c.system === CONSENT_TYPE_CODE_SYSTEM && c.code === ConsentTypeCode.CERT_PERIOD
          )
        )
    ) ?? []

  const certPeriodEpisodesOfCare =
    patient?.EpisodeOfCareList?.filter((e): e is BaseEpisodeOfCareFragment => !!e) ?? []

  // Map consents to episodes of care because we filter by current authorization
  // on patient data query so we could have episodes of care without consents
  const certPeriods = certPeriodConsents
    .map((consent): BaseEpisodeOfCareWithConsentFragment | undefined => {
      const episodeOfCare = certPeriodEpisodesOfCare.find((epiCare) =>
        consent.extension?.some(
          (ex) =>
            ex.url === CONSENT_EPISODE_OF_CARE_EXTENSION_URL &&
            ex.valueReference?.reference === `EpisodeOfCare/${epiCare.id}`
        )
      )

      return episodeOfCare
        ? {
            ...episodeOfCare,
            consent
          }
        : undefined
    })
    // Filter out undefined values (the ones that didn't have a matching episode of care for some reason)
    .filter((certPeriod): certPeriod is BaseEpisodeOfCareWithConsentFragment => {
      return certPeriod ? true : false
    })
    .sort(sortCertPeriods)
  return certPeriods
}

export const isCertPeriodActive = (
  certPeriod: BaseEpisodeOfCareWithConsentFragment,
  timezone?: string
): boolean => {
  // We need to check the status from any potentially active status history entries, in addition to checking the status directly
  // to determine the current status of the episode of care
  const statusFromStatusHistory = getEpisodeOfCareStatusFromStatusHistory(certPeriod, timezone)
  const episodeOfCareStatusIsActive =
    certPeriod.status === EpisodeOfCareStatusEnum.ACTIVE &&
    statusFromStatusHistory === EpisodeOfCareStatusEnum.ACTIVE &&
    certPeriod.consent?.status === ConsentStatus.ACTIVE

  return episodeOfCareStatusIsActive
}

const todayIsWithinCertPeriod = (
  certPeriod: BaseEpisodeOfCareWithConsentFragment,
  timezone?: string
): boolean => {
  const periodStart = certPeriod.period?.start
    ? transformToTimezoneOrUtc(certPeriod.period.start, timezone)
    : null
  const periodEnd = certPeriod.period?.end
    ? transformToTimezoneOrUtc(certPeriod.period?.end, timezone)
    : null
  const today = transformToTimezoneOrUtc(dayjs().format(), timezone)
  let todayIsWithinPeriod = true
  if (periodStart && periodEnd) {
    todayIsWithinPeriod =
      periodStart?.isValid() && periodEnd?.isValid()
        ? today.isBetween(periodStart, periodEnd, 'day', '[]')
        : false
  } else if (periodStart) {
    // if periodEnd is not available, we will check if the current date is after the start date
    todayIsWithinPeriod = periodStart?.isValid()
      ? today.isSame(periodStart, 'day') || today.isAfter(periodStart, 'day')
      : false
  } else {
    todayIsWithinPeriod = false
  }

  return todayIsWithinPeriod
}

export const getCurrentCertPeriod = (
  patient: BasePatientWithEpisodeOfCareAndConsentFragment,
  timezone?: string,
  isActive = true,
  checkTimePeriod = true
): BaseEpisodeOfCareWithConsentFragment | null => {
  const certPeriods = getPatientCertPeriods(patient)
  const currentCertPeriod =
    certPeriods.find((certPeriod) => {
      const periodStart = certPeriod.period?.start
        ? transformToTimezoneOrUtc(certPeriod.period.start, timezone)
        : null
      const periodEnd = certPeriod.period?.end
        ? transformToTimezoneOrUtc(certPeriod.period?.end, timezone)
        : null
      const today = transformToTimezoneOrUtc(dayjs().format(), timezone)
      let todayIsWithinPeriod = true
      if (checkTimePeriod && periodStart && periodEnd) {
        todayIsWithinPeriod =
          periodStart?.isValid() && periodEnd?.isValid()
            ? today.isBetween(periodStart, periodEnd, 'day', '[]')
            : false
      } else if (checkTimePeriod && periodStart) {
        // if periodEnd is not available, we will check if the current date is after the start date
        todayIsWithinPeriod = periodStart?.isValid()
          ? today.isSame(periodStart, 'day') || today.isAfter(periodStart, 'day')
          : false
      } else if (checkTimePeriod) {
        todayIsWithinPeriod = false
      }
      const certPeriodActive = isCertPeriodActive(certPeriod, timezone)

      const isCurrentCertPeriod = periodStart && periodEnd && todayIsWithinPeriod
      return isActive ? certPeriodActive && isCurrentCertPeriod : isCurrentCertPeriod
    }) ?? null
  return currentCertPeriod
}

export const getNextCertPeriod = (
  patient: BasePatientWithEpisodeOfCareAndConsentFragment
): BaseEpisodeOfCareWithConsentFragment | null => {
  const certPeriods = getPatientCertPeriods(patient)
  if (!certPeriods) {
    // If there is no current active period, return none
    // and the form component will trigger a required message validation
    return null
  }

  const certPeriodsDesc = certPeriods.sort(sortCertPeriods).reverse()
  let previous: BaseEpisodeOfCareWithConsentFragment | null = null
  for (const certPeriod of certPeriodsDesc) {
    if (certPeriod.status === EpisodeOfCareStatusEnum.ACTIVE) {
      return previous
    }

    previous = certPeriod
  }

  return previous
}

export const getPreviousCertPeriod = (
  certPeriods: BaseEpisodeOfCareWithConsentFragment[] | null
): BaseEpisodeOfCareWithConsentFragment | null => {
  if (!certPeriods) {
    // If there is no current active period, return none
    return null
  }

  const certPeriodsAsc = [...certPeriods].sort(sortCertPeriods)
  let previous: BaseEpisodeOfCareWithConsentFragment | null = null
  for (const certPeriod of certPeriodsAsc) {
    if (todayIsWithinCertPeriod(certPeriod)) {
      return previous ?? certPeriod
    }

    previous = certPeriod
  }

  return previous
}

export const getPatientAuthorizations = (
  patientData: BasePatientWithConsentFragment
): BaseConsentFragment[] | undefined => {
  const authConsents = patientData.ConsentList?.filter(
    (consent): consent is BaseConsentFragment =>
      !!consent?.category?.find((code) =>
        code.coding?.find(
          (c) => c.system === CONSENT_TYPE_CODE_SYSTEM && c.code === ConsentTypeCode.AUTHORIZATION
        )
      )
  )
  return authConsents
}

export const getPatientActiveAuthorization = (
  patientData: BasePatientWithConsentFragment,
  timezone?: string,
  checkTimePeriod?: boolean
): BaseConsentFragment | null => {
  const authConsents = getPatientAuthorizations(patientData)
  const currentActiveAuth =
    authConsents
      ?.sort((a, b) => {
        const aStart = a.provision?.period?.start
        const bStart = b.provision?.period?.start
        if (!aStart) return 1
        if (!bStart) return -1

        return dayjs(bStart).valueOf() - dayjs(aStart).valueOf()
      })
      ?.find((consent) => {
        const consentStatus = consent.status
        const provisionStatus = getAuthorizationStatusFromProvisionRule(consent, timezone)
        const consentIsActive =
          consentStatus === ConsentStatus.ACTIVE && provisionStatus === ConsentStatus.ACTIVE

        const authStart = consent?.provision?.period?.start
          ? transformToTimezoneOrUtc(consent?.provision?.period?.start, timezone)
          : null
        const authEnd = consent?.provision?.period?.end
          ? transformToTimezoneOrUtc(consent?.provision?.period?.end, timezone)
          : null
        const isWithinAuthPeriod =
          authStart?.isValid() && authEnd?.isValid()
            ? transformToTimezoneOrUtc(dayjs().format(), timezone).isBetween(
                authStart,
                authEnd,
                null,
                '[]'
              ) || !checkTimePeriod
            : false

        return consentIsActive && isWithinAuthPeriod
      }) ?? null

  return currentActiveAuth
}

export const findCertPeriod = (
  consentList: (BaseConsentFragment | null)[],
  episodeOfCareList: (BaseEpisodeOfCareFragment | null)[],
  consentId: string,
  caregiverTimezone?: string
): [Dayjs, Dayjs] | undefined => {
  const assessmentConsent = consentList
    .filter((e): e is BaseConsentFragment => !!e)
    .find((consent) => consent.id === consentId)

  if (assessmentConsent && assessmentConsent?.extension) {
    const episodeOfCareReference = findExtension(
      assessmentConsent.extension,
      CONSENT_EPISODE_OF_CARE_EXTENSION_URL
    )?.valueReference?.reference

    const assessmentEpisodeOfCare = episodeOfCareList
      .filter((e): e is BaseEpisodeOfCareFragment => !!e)
      .find((e) => `EpisodeOfCare/${e.id}` === episodeOfCareReference)

    const assessmentPeriod = assessmentEpisodeOfCare?.period

    if (assessmentPeriod?.start && assessmentPeriod?.end) {
      const periodRange: [Dayjs, Dayjs] = [
        dayjs(assessmentPeriod.start).tz(caregiverTimezone),
        dayjs(assessmentPeriod.end).tz(caregiverTimezone)
      ]

      return periodRange
    }
  }
}

export const findManagingOrganizationId = (
  managingOrganization?: Reference<Organization> | null
): string => {
  let orgId: string
  if (managingOrganization?.id) {
    orgId = managingOrganization?.id
  } else if (managingOrganization?.identifier?.id) {
    orgId = managingOrganization?.identifier?.id
  } else if (managingOrganization?.reference) {
    orgId = managingOrganization?.reference.split('/')[1]
  } else {
    throw new UserError('Unable to generate document without organization ID.')
  }
  return orgId
}

export const buildManagingOrganizationReference = (
  managingOrganization?: Reference<Organization> | null
): string => {
  const orgId = findManagingOrganizationId(managingOrganization)
  return managingOrganization?.reference ?? `Organization/${orgId}`
}
