import React from 'react'
import Link from 'next/link'
import {
  ArrowsRight,
  BarChart,
  Calendar,
  Heart,
  HeartWave,
  Home,
  Payroll,
  People,
  Settings
} from 'public/assets/icons'
import { FeatureFlagKey, FeatureFlags } from 'hooks/useFeatureFlags'
import { AbbyAccessPolicy } from 'utils/accessPolicy'
import { AntMenuItem, MenuItemKey } from './types'

const getLabelLink = (label, key): JSX.Element => {
  return <Link href={key}>{label}</Link>
}

const getItem = ({
  label,
  key,
  icon,
  children,
  type,
  disabled,
  roles,
  devOnly = false,
  featureFlag
}: Partial<AntMenuItem> & { label: string; key: string }): AntMenuItem => {
  let labelLink
  if (!children) {
    labelLink = getLabelLink(label, key)
  }
  return {
    key,
    icon,
    children,
    label: labelLink ?? label,
    type,
    disabled,
    roles,
    devOnly,
    featureFlag
  }
}

export const allMenuItems = [
  getItem({
    label: 'Home',
    key: MenuItemKey.HOME,
    icon: <Home />,
    children: [
      getItem({
        label: 'Task Center',
        key: MenuItemKey.TASKS
      })
    ]
  }),
  getItem({
    label: 'Patients',
    key: MenuItemKey.PATIENTS,
    icon: <Heart />
  }),
  getItem({
    label: 'Employees',
    key: MenuItemKey.EMPLOYEES,
    icon: <People />
  }),
  getItem({
    label: 'Clinical',
    key: MenuItemKey.CLINICAL,
    icon: <HeartWave />,
    roles: [
      AbbyAccessPolicy.ADMIN,
      AbbyAccessPolicy.CLINICAL,
      AbbyAccessPolicy.CARE_OPS,
      AbbyAccessPolicy.GM
    ],
    children: [
      getItem({
        label: 'QA',
        key: MenuItemKey.CLINICAL_QA
      }),
      getItem({
        label: 'Assessments',
        key: MenuItemKey.CLINICAL_ASSESSMENTS
      }),
      getItem({
        label: 'Providers',
        key: MenuItemKey.CLINICAL_PROVIDERS
      })
    ]
  }),
  getItem({
    label: 'Flows',
    key: MenuItemKey.FLOWS,
    icon: <ArrowsRight />,
    featureFlag: { key: FeatureFlagKey.Flows, ifDisabled: false, ifEnabled: true }
  }),
  getItem({
    label: 'Schedule',
    key: MenuItemKey.SCHEDULE,
    icon: <Calendar />
  }),
  getItem({
    label: 'Payroll',
    key: MenuItemKey.PAYROLL,
    icon: <Payroll />,
    featureFlag: {
      key: 'enableNewReportsV2',
      ifDisabled: true,
      ifEnabled: false
    },
    roles: [
      AbbyAccessPolicy.ADMIN,
      AbbyAccessPolicy.CLINICAL,
      AbbyAccessPolicy.CARE_OPS,
      AbbyAccessPolicy.FINANCE,
      AbbyAccessPolicy.GM
    ]
  }),
  getItem({
    label: 'Reports',
    key: MenuItemKey.REPORTS,
    icon: <BarChart />,
    featureFlag: {
      key: 'enableNewReportsV2',
      ifDisabled: true,
      ifEnabled: false
    },
    roles: [
      AbbyAccessPolicy.ADMIN,
      AbbyAccessPolicy.CLINICAL,
      AbbyAccessPolicy.CARE_OPS,
      AbbyAccessPolicy.BILLING,
      AbbyAccessPolicy.FINANCE,
      AbbyAccessPolicy.GM
    ]
  }),
  getItem({
    label: 'Reports',
    key: MenuItemKey.REPORTS_V2,
    icon: <BarChart />,
    featureFlag: {
      key: 'enableNewReportsV2',
      ifDisabled: false,
      ifEnabled: true
    },
    roles: [
      AbbyAccessPolicy.ADMIN,
      AbbyAccessPolicy.CLINICAL,
      AbbyAccessPolicy.CARE_OPS,
      AbbyAccessPolicy.BILLING,
      AbbyAccessPolicy.FINANCE,
      AbbyAccessPolicy.GM
    ]
  }),
  getItem({
    label: 'Admin',
    key: MenuItemKey.ADMIN,
    icon: <Settings />,
    roles: [AbbyAccessPolicy.ADMIN, AbbyAccessPolicy.CARE_OPS],
    children: [
      getItem({
        label: 'Onboarding',
        key: MenuItemKey.ONBOARDING
      })
    ]
  })
]

export const getAccessibleMenuItems = (
  featureFlags: FeatureFlags,
  accessPolicyList: AbbyAccessPolicy[]
): AntMenuItem[] => {
  return allMenuItems.filter((item) => {
    const failsDevOnly = item.devOnly && process.env.NODE_ENV !== 'development'
    if (failsDevOnly) return false

    const requiredRoleSet = new Set(item.roles)
    const accessPolicySet = new Set(accessPolicyList)

    if (
      item.roles?.length &&
      (!accessPolicyList.length || !requiredRoleSet.intersection(accessPolicySet).size)
    ) {
      return false
    }

    if (item.featureFlag) {
      const flagKey = item.featureFlag.key
      const flagValue = featureFlags[flagKey as keyof FeatureFlags]

      if (item.featureFlag.ifDisabled) {
        return !flagValue
      }
      if (item.featureFlag.ifEnabled) {
        return flagValue
      }
    }
    return true
  })
}
