import {
  Annotation,
  CodeableConcept,
  Coding,
  Extension,
  Identifier,
  Reference as MedplumReference,
  Resource,
  Signature
} from '@medplum/fhirtypes'
import { encodeBase64 } from '@medplum/core'
import {
  type Extension as GraphqlExtension,
  type Reference as GraphqlReference
} from 'generated/graphql'
import { SignatureFormat, signatureFormatType } from './Signature/constants'

/**
 * Sanitizes code values by converting the string to lowercase, replacing spaces with underscores, and removes any characters that are not alphanumeric, underscores, or hyphens.
 * @param inputString Code string to be sanitized
 * @returns String
 */
export const sanitizeCodeableConceptCode = (inputString: string): string => {
  const lowercaseString = inputString.toLowerCase()
  const underscoredString = lowercaseString.replace(/\s+/g, '_')
  const sanitizedString = underscoredString.replace(/[^a-z0-9_-]/g, '')
  return sanitizedString
}

/**
 * Attempts to find a Coding in a list of CodeableConcept
 * @param codes A list of CodeableConcepts
 * @param code The code string we're looking for
 * @returns Coding
 */
export const findCode = (codes: CodeableConcept[], code: string): Coding | undefined =>
  codes.find((c) => c.coding?.find((co) => co.code === code))

/**
 * Attempts to find an Extension in a list of Extensions
 * @param codes A list of extensions
 * @param code The url string we're looking for
 * @returns Extension
 */
export const findExtension = <T extends Extension | GraphqlExtension>(
  extensions: T[],
  url: string
): T | undefined => extensions.find((e) => e.url === url)

/**
 * A helper to define a CodeableConcept
 * @param code LOINC code or Abbycare code identifying the CodeableConcept
 * @param text Display string
 * @param system The system responsible for the code
 * @returns CodeableConcept
 */
export const createCodeableConcept = ({
  system,
  code,
  text
}: {
  system: string
  code: string
  text: string
}): CodeableConcept => ({
  coding: [
    {
      system,
      code: sanitizeCodeableConceptCode(code),
      display: text
    }
  ],
  text
})

export const createValueCodingExtension = ({
  url,
  system,
  valueCodingCode
}: {
  url: string
  system: string
  valueCodingCode: string
}): Extension => ({
  url,
  valueCoding: {
    system,
    code: sanitizeCodeableConceptCode(valueCodingCode)
  }
})

/**
 * A helper to define a Reference when we don't have the full FHIR object to use
 * medplum/core createReference
 *
 * @param reference A reference to a location at which the other resource is found.
 * @param text The expected type of the target of the reference.
 * @param identifier An identifier for the target resource.
 * @param display Plain text narrative that identifies the resource in addition to the resource reference.
 *
 * @returns Reference
 */
export const createReference = <T extends Resource>({
  id,
  reference,
  type,
  identifier,
  display
}: {
  reference: string
  type: MedplumReference['type'] | string
  id: string
  display: string
  identifier?: {
    id?: string
  }
}): MedplumReference<T> => ({
  id,
  reference,
  type: type as MedplumReference['type'],
  identifier: identifier,
  display: display
})

/**
 * Creates a single Annotation data type.
 *
 * @param {MedplumReference<Organization | Patient | Practitioner | RelatedPerson> | undefined} authorReference - The reference to the person who authored the Annotation.
 * @param {string} text - The text associated with the Annotation.
 * @returns {Annotation} An Annotation data type.
 */

export const createAnnotation = ({
  authorReference,
  text,
  extension
}: {
  authorReference?: Annotation['authorReference']
  text?: string
  extension?: Extension[]
}): Annotation => ({
  authorReference,
  text: String(text),
  time: new Date().toISOString(),
  extension
})

/**
 * Creates an identifier data type.
 * @param system The namespace for the identifier value.
 * @param value The value that is unique within the namespace.
 * @param type_system The namespace for the identifier type.
 * @param type_code The type code for the identifier.
 * @param type_display The display for the identifier type.
 * @returns Identifier data type.
 */
export const createIdentifier = ({
  system,
  value,
  type_system,
  type_code,
  type_display
}: {
  system: string
  value: string
  type_system?: string
  type_code?: string
  type_display?: string
}): Identifier => {
  const trimmedSystem = system.trim()
  const trimmedValue = value.trim()
  const trimmedTypeSystem = type_system?.trim() ?? ''
  const trimmedTypeCode = type_code?.trim() ?? ''

  if (trimmedSystem === '') {
    throw new Error('System cannot be an empty string')
  }
  if (trimmedValue === '') {
    throw new Error('Value cannot be an empty string')
  }
  if (type_system && trimmedTypeSystem === '') {
    throw new Error('Type system cannot be an empty string')
  }
  if (type_code && trimmedTypeCode === '') {
    throw new Error('Type code cannot be an empty string')
  }

  if (!type_system || !type_code || !type_display) {
    return {
      system: trimmedSystem,
      value: trimmedValue
    }
  }

  return {
    system: trimmedSystem,
    value: trimmedValue,
    type: createCodeableConcept({
      system: trimmedTypeSystem,
      code: trimmedTypeCode,
      text: type_display
    })
  }
}

/**
 * Creates a single Signature data type.
 *
 * @param who - The reference to the person who signed the document.
 * @param onBehalfOf - The reference to the person who is represented by the document.
 * @param type - The Coding that describes the type of digital signature.
 * @param signature - The digital signature received from the signer.
 * @param timestamp - The time the signature was captured.
 * @param signatureType - The type of signature (e.g. text or FaceID).
 * @returns {Signature} A Signature data type.
 */

export const createSignature = ({
  who,
  onBehalfOf,
  type,
  signature,
  timestamp,
  signatureFormat
}: {
  who: Signature['who']
  onBehalfOf: Signature['onBehalfOf']
  type: Signature['type']
  signature: string
  timestamp: string
  signatureFormat?: SignatureFormat
}): Signature => {
  const encodedSignature = encodeBase64(signature)
  return {
    who,
    onBehalfOf,
    type,
    when: timestamp,
    targetFormat: signatureFormatType,
    data: encodedSignature,
    sigFormat: signatureFormat
  }
}

/**
 * Splits a string into two parts based on a delimiter.
 *
 * @param {string} referenceString - The input string to split.
 * @returns {[string, string]} An array containing the resource type (first element) and ID (second element).
 */
export const parseReferenceString = (referenceString: string): [string, string] => {
  const [resourceType, id, ...rest] = referenceString.split('/')
  if (!resourceType || !id || rest.length > 0) throw new Error('Invalid reference string.')

  return [resourceType, id]
}

type EntityReference =
  | Pick<MedplumReference, 'id' | 'type' | 'reference' | 'identifier'>
  | Pick<GraphqlReference, 'id' | 'type' | 'reference' | 'identifier'>

export const isSameEntity = (one: EntityReference, two: EntityReference): boolean => {
  const hasSameIdAndType = Boolean(
    one.id && one.type && two.id && two.type && one.id === two.id && one.type === two.type
  )
  const hasSameReference = Boolean(
    one.reference && two.reference && one.reference === two.reference
  )
  const hasSameIdentifier = Boolean(
    one.identifier && two.identifier && one.identifier === two.identifier
  )

  return hasSameIdAndType || hasSameReference || hasSameIdentifier
}

export const isCodingObject = (obj: any): boolean => {
  return typeof obj === 'object' && 'code' in obj && 'display' in obj
}

type OptionalFragment<T> = Record<string, never> | T | null | undefined

export const isValidFragmentType = <T>(value: OptionalFragment<T>): value is T =>
  value !== null &&
  value !== undefined &&
  typeof value === 'object' &&
  Object.keys(value).length !== 0

export const getValidatedFragment = <T>(value: OptionalFragment<T>): T | null => {
  return isValidFragmentType<T>(value) ? value : null
}

/**
 * Gets a codeable concept value with a specified URL from an array of extensions.
 *
 * @param {Extension[] | undefined | null} extension - The array of extensions.
 * @returns {CodeableConcept} The Codeable Concept value associated with the target extension URL.
 */

export const getCodeableConceptExtensionValue = (
  extension: Extension[] | undefined,
  url: string
): CodeableConcept | undefined | null => {
  const value = extension?.find((ext) => ext.url === url)?.valueCodeableConcept
  return value
}

/**
 * Gets a boolean value with a specified URL from an array of extensions.
 *
 * @param {Extension[] | undefined | null} extension - The array of extensions.
 * @returns {boolean} The boolean value associated with the target extension URL.
 */

export const getBooleanExtensionValue = (
  extension: Extension[] | undefined | null,
  url: string
): boolean | undefined => {
  const value = extension?.find((ext) => ext.url === url)?.valueBoolean
  return value
}

/**
 * Gets a string value with a specified URL from an array of extensions.
 *
 * @param {Extension[] | undefined | null} extension - The array of extensions.
 * @returns {string} The string value associated with the target extension URL.
 */

export const getStringExtensionValue = (
  extension: Extension[] | undefined | null,
  url: string
): string | undefined => {
  const value = extension?.find((ext) => ext.url === url)?.valueString
  return value
}
