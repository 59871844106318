import {
  CONSENT_ACTION_CODE_SYSTEM,
  CONSENT_CHARTING_CATEGORY_EXTENSION_URL,
  ConsentActionCode,
  ConsentActorRoleCode,
  ConsentCategoryCode,
  ConsentChartingCategory,
  ConsentStatus
} from 'fhir/Consent/constants'
import { BaseConsentFragment, BaseCoverageFragment, ConsentProvisionActor } from 'generated/graphql'
import { isTimePeriodInEffect } from 'utils/dates'

export const getProvisionAuthor = (
  consent: BaseConsentFragment
): Partial<ConsentProvisionActor> | null => {
  const performer = consent.provision?.actor?.find(
    (actor) => actor?.role?.coding?.[0].code === ConsentActorRoleCode.AUTHOR
  )

  return performer ?? null
}

const consentTypeHierarchy: string[] = [ConsentCategoryCode.HOME_HEALTH, '']

export const getPrimaryConsentCategoryCode = (consent: BaseConsentFragment): string | null => {
  const categories = consent.category ?? []

  const sorted = [...categories].sort(
    (a, b) =>
      consentTypeHierarchy.indexOf(a.coding?.find((code) => !!code)?.code ?? '') -
      consentTypeHierarchy.indexOf(b.coding?.find((code) => !!code)?.code ?? '')
  )

  const primaryCategory = sorted.find((val) => !!val)
  return primaryCategory?.coding?.at(0)?.code ?? null
}

export const getCoverageIssuedByPerformer = (
  consent: BaseConsentFragment,
  coverageList: BaseCoverageFragment[]
): BaseCoverageFragment | undefined => {
  const coverageIssuedByPerformer = coverageList.find((coverage) =>
    coverage?.payor?.some((payor) =>
      consent.performer?.some((perf) => {
        return !!perf.reference && !!payor.reference && perf.reference === payor.reference
      })
    )
  )

  return coverageIssuedByPerformer
}

export const getAuthorizationHours = (
  authorizationConsent: BaseConsentFragment | null | undefined
): number | undefined => {
  if (!authorizationConsent) return undefined

  const careHours = authorizationConsent?.careHours?.at(0)?.valueDecimal

  return careHours ?? undefined
}

export const getAuthorizationChartingCategory = (
  authorizationConsent: BaseConsentFragment | null | undefined
): ConsentChartingCategory | null => {
  if (!authorizationConsent) return null

  return (
    (authorizationConsent.extension?.find(
      (extension) => extension.url === CONSENT_CHARTING_CATEGORY_EXTENSION_URL
    )?.valueString as ConsentChartingCategory) ?? null
  )
}

export const getAuthorizationStatusFromProvisionRule = (
  consentResource: BaseConsentFragment,
  timezone?: string
): ConsentStatus => {
  const consentStatus = consentResource.status
  const nestedProvisionRule = consentResource.provision?.nestedProvision
  const { start, end } = nestedProvisionRule?.period ?? {}
  const isDischargeAction = nestedProvisionRule?.action?.find((action) =>
    action.coding?.find(
      (coding) =>
        coding.system === CONSENT_ACTION_CODE_SYSTEM && coding.code === ConsentActionCode.DISCHARGE
    )
  )
  const isCurrentlyActive = isTimePeriodInEffect(timezone, start, end)

  return isDischargeAction && isCurrentlyActive
    ? ConsentStatus.INACTIVE
    : (consentStatus as ConsentStatus)
}
