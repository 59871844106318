import React, { createContext } from 'react'

export enum AutosaveStatus {
  SUCCESS,
  SAVING,
  ERROR
}

export interface AssessmentLayoutContextType {
  autosaveDate?: Date
  setAutosaveDate: React.Dispatch<React.SetStateAction<Date>>
  autosaveStatus: AutosaveStatus
  setAutosaveStatus: React.Dispatch<React.SetStateAction<AutosaveStatus>>
  onAssessmentCompleteClick: () => void
  setOnAssessmentCompleteClick: React.Dispatch<React.SetStateAction<() => void>>
  isAssessmentSubmitting: boolean
  setIsAssessmentSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  hasRoleToEdit: boolean
  setHasRoleToEdit: React.Dispatch<React.SetStateAction<boolean>>
  assessmentIsComplete: boolean
  setAssessmentIsComplete: React.Dispatch<React.SetStateAction<boolean>>
}

const AssessmentLayoutContext = createContext<AssessmentLayoutContextType>({
  setAutosaveDate: () => {}, // Function to update the last autosaved time
  autosaveStatus: AutosaveStatus.SUCCESS,
  setAutosaveStatus: () => {}, // Function to update the autosave progress
  onAssessmentCompleteClick: () => {}, // This will hold the submit function for the current form
  setOnAssessmentCompleteClick: () => {}, // Function to update the submit function
  isAssessmentSubmitting: false,
  setIsAssessmentSubmitting: () => {},
  hasRoleToEdit: false,
  setHasRoleToEdit: () => {},
  assessmentIsComplete: false,
  setAssessmentIsComplete: () => {}
})

export default AssessmentLayoutContext
